import * as Yup from 'yup';
export const updateConfigSchema = Yup.object().shape({
  payoutFee: Yup.number()
    .required('Commission is required')
    .typeError('Must be a number')
    .positive()
    .test(
      'maxDigitsAfterDecimal',
      'number field must have 2 digits after decimal or less',
      number => /^\d+(\.\d{1,2})?$/.test(String(number)),
    ),
  paymentFee: Yup.number()
    .required('Commission is required')
    .typeError('Must be a number')
    .positive()
    .test(
      'maxDigitsAfterDecimal',
      'number field must have 2 digits after decimal or less',
      number => /^\d+(\.\d{1,2})?$/.test(String(number)),
    ),
});
