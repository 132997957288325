import { baseApi } from '@app/state';
import { TProject } from '@app/features/Projects';
import { ProjectStatusesEnum } from '@app/features/Payments/enum/projectStatuses.enum';
import { TListRequest } from '@app/types';
export type TCreateProjectEarning = {
  id?: number;
  amount: number;
};
export type TAdmin = {
  emil?: string;
  id: number;
};
export type TProjectEarning = {
  status: ProjectStatusesEnum;
  admin: TAdmin;
  id: number;
  project: TProject;
  amount: number;
};

export type TProjectDistributions = {
  amount: number;
  artist_id: number;
  id: number;
  project_earning_id: number;
  project_id: number;
};
export type TCommission = {
  payment_fee: number;
  payout_fee: number;
};

export const api = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Payments'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      fetchPayments: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: 'admin/payments',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Payments'],
      }),
      fetchRefunds: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: 'admin/payments/refunds',
            method: 'GET',
            params,
          };
        },
      }),
      fetchProjectEarnings: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: 'admin/payments/projects/earnings',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Payments'],
      }),
      fetchUsersEarnings: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: 'admin/payments/users/earnings',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Payments'],
      }),
      fetchPayouts: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: 'admin/payments/payouts',
            method: 'GET',
            params,
          };
        },
      }),
      createEarning: builder.mutation<any, TCreateProjectEarning>({
        query: body => {
          return {
            url: `admin/projects/${body.id}/earnings`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Payments'],
      }),
      fetchEarning: builder.query<TProjectEarning, string>({
        query: id => {
          return {
            url: `admin/payments/projects/earnings/${id}`,
            method: 'GET',
          };
        },
        providesTags: ['Payments'],
      }),
      distributeEarnings: builder.mutation<
        TProjectEarning,
        { projectId: number; earningId: number }
      >({
        query: ({ projectId, earningId }) => {
          return {
            url: `admin/projects/${projectId}/earnings/${earningId}/distribute`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Payments'],
      }),
      fetchProjectDistribution: builder.query<
        TProjectDistributions[],
        { projectId: number; earningId: number }
      >({
        query: ({ projectId, earningId }) => {
          return {
            url: `admin/projects/${projectId}/earnings/${earningId}/distributions`,
            method: 'GET',
          };
        },
        providesTags: ['Payments'],
      }),
      fetchPaymentConfig: builder.query<TCommission, any>({
        query: () => {
          return {
            url: 'admin/config/payments',
            method: 'GET',
          };
        },
        providesTags: ['Payments'],
      }),
      updateConfig: builder.mutation<TCommission, TCommission>({
        query: body => {
          return {
            url: 'admin/config/payments',
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['Payments'],
      }),
    }),
  });
