import { api } from '../state';

export const {
  useFetchPaymentsQuery,
  useFetchProjectEarningsQuery,
  useFetchRefundsQuery,
  useCreateEarningMutation,
  useFetchEarningQuery,
  useDistributeEarningsMutation,
  useLazyFetchProjectDistributionQuery,
  useFetchPaymentConfigQuery,
  useUpdateConfigMutation,
  useFetchUsersEarningsQuery,
  useFetchPayoutsQuery,
} = api;
