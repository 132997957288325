import { createSlice } from '@reduxjs/toolkit';

import store from '@app/state/store';

const slice = createSlice({
  name: 'artists',
  initialState: {},
  reducers: {},
  extraReducers: {},
});

store.injectReducer(slice.name, slice.reducer);

export default slice;
