import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useUpdateConfigMutation } from '@app/features/Payments/hooks';

const ChangeCommissionModal = ({
  open,
  handleClose,
  closeEdit,
  values,
}: {
  open: boolean;
  handleClose: () => void;
  closeEdit: () => void;
  values: { paymentFee: string; payoutFee: string };
}) => {
  const [updateConfig, { isSuccess, isLoading, isError, reset }] = useUpdateConfigMutation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {!isSuccess && !isError && 'Changing commissions'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isError && 'Some error occurred'}
          {isSuccess && !isError
            ? 'The commission percentage has been changed'
            : ' Are you sure you want to apply the changes?'}
        </DialogContentText>
        <DialogActions>
          {isSuccess ? (
            <Button
              onClick={() => {
                handleClose();
                closeEdit();
                reset();
              }}>
              Understood
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  updateConfig({
                    payment_fee: +values.paymentFee,
                    payout_fee: +values.payoutFee,
                  });
                }}>
                {isLoading ? <CircularProgress size={20} /> : ' Change commission'}
              </Button>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeCommissionModal;
