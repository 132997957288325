import React from 'react';
import { Avatar, Box, Chip, Grid, Typography } from '@mui/material';
import { TArtist } from '@app/features/Artists/state';
import Row from './components/Row';

const ArtistInfo = ({ artist }: { artist: TArtist }) => {
  return (
    <Grid xs={5} spacing={2} container direction="column">
      <Box ml={2} mt={5} mb={2}>
        <Typography variant={'h6'}>Major info</Typography>
      </Box>
      <Row label={'Image'} children={<Avatar src={artist.avatar_url} />} />
      <Row label={'Stage Name'} children={<Typography>{artist.profile?.stage_name}</Typography>} />
      <Row label={'First Name'} children={<Typography>{artist.first_name || '-'}</Typography>} />
      <Row label={'Last Name'} children={<Typography>{artist.last_name || '-'}</Typography>} />
      <Box ml={2} mt={5} mb={2}>
        <Typography variant={'h6'}>Artist Type</Typography>
      </Box>
      <Row
        label={'Genres'}
        children={
          <Typography>
            {artist.genres.map(el => <Chip style={{ marginRight: 8 }} label={el.name} />) || '-'}
          </Typography>
        }
      />
      <Row label={'Type'} children={<Typography>{artist.profile?.artist_type}</Typography>} />
      <Row
        label={'Featured'}
        children={<Typography>{String(artist.is_featured) || 'False'}</Typography>}
      />
      <Row label={'Followers'} children={<Typography>{artist.followers_count}</Typography>} />
      <Box ml={2} mt={5} mb={2}>
        <Typography variant={'h6'}>Social Media</Typography>
      </Box>
      <Row
        label={'TikTok'}
        children={<Typography>{artist.profile?.tiktok_url || '-'}</Typography>}
      />
      <Row
        label={'Instagram'}
        children={<Typography>{artist.profile?.instagram_url || '-'}</Typography>}
      />
      <Row
        label={'Spotify'}
        children={<Typography>{artist.profile?.spotify_url || '-'}</Typography>}
      />
      <Row
        label={'Apple Music'}
        children={<Typography>{artist.profile?.apple_music_url || '-'}</Typography>}
      />
      <Box ml={2} mt={5} mb={2}>
        <Typography variant={'h6'}>Verification</Typography>
      </Box>
      <Row
        label={'Identity verification status'}
        children={<Typography>{artist.verification_status || '-'}</Typography>}
      />
      <Box ml={2} mt={5} mb={2}>
        <Typography variant={'h6'}>Stripe verification status</Typography>
      </Box>
      <Row
        label={'Is onboarding passed'}
        children={
          <Typography>
            {String(artist.stripe_payment_profile?.is_onboarding_passed || 'false')}
          </Typography>
        }
      />
      <Row
        label={'Is document verified'}
        children={
          <Typography>
            {String(artist.stripe_payment_profile?.is_document_verified || 'false')}
          </Typography>
        }
      />
      <Row label={'Email'} children={<Typography>{artist.email}</Typography>} />
    </Grid>
  );
};

export default ArtistInfo;
