import React from 'react';
import DataTable from '@app/components/DataTable';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Columns } from './components/Columns';
import { useFetchGenresQuery } from '@app/features/Genres/hooks';

const Genres = () => {
  const { t } = useTranslation();

  const { data, isFetching } = useFetchGenresQuery({});

  const genres = data || [];

  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography variant="h4">Genres</Typography>
          <Link style={{ color: '#fff', textDecoration: 'none' }} to="create-genre">
            <Button variant="contained" color="primary">
              {t('genres.newGenre')}
            </Button>
          </Link>
        </Box>
      </Stack>
      <DataTable
        columns={Columns()}
        pageSize={10}
        rows={genres}
        hideFooter
        loading={isFetching}
        rowCount={genres.length}
      />
    </>
  );
};

export default Genres;
