import React from 'react';
import { useFetchTermsQuery } from '@app/features/TermsAndConditions/hooks';
import { Typography } from '@mui/material';
import parse from 'html-react-parser';

const TermsAndConditions = () => {
  const { data } = useFetchTermsQuery({});
  return (
    <Typography fontSize={18} p={1}>
      {data?.terms && parse(data?.terms.replace(/\n/g, '<br/>'))}
    </Typography>
  );
};

export default TermsAndConditions;
