import { api } from '../state';

export const {
  useFetchFansQuery,
  useCreateFanMutation,
  useFetchFanInfoQuery,
  useUpdateFanMutation,
  useFetchFanQuery,
  useFetchFanProjectsQuery,
  useBlockFanMutation,
  useUnblockFanMutation,
  useDeleteFanMutation,
} = api;
