import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  useApproveCompletionMutation,
  useRejectProjectCompletionMutation,
} from '@app/features/Projects/hooks';

const ResolveProject = ({ projectId }: { projectId: string }) => {
  const [open, setOpen] = useState(false);
  const [approve] = useApproveCompletionMutation();
  const [rejectProject] = useRejectProjectCompletionMutation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Alert severity="error">
          This project has not collected the intended contribution amount. Please select the
          “Resolve” to decide on the fans’ contributions.
        </Alert>
        <Button
          onClick={() => handleClickOpen()}
          style={{ width: 137, height: 42 }}
          variant="contained">
          Resolve
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Resolve'}</DialogTitle>
        <Box style={{ padding: '16px 20px' }}>
          <Button onClick={() => approve(projectId)}>Approve</Button>
          <Button onClick={() => rejectProject(projectId)} color="error">
            Reject
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ResolveProject;
