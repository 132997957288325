import React, { useCallback, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { Autocomplete, Box, debounce, Divider, Grid, Typography } from '@mui/material';
import TextInput from '@app/components/TextInput';
import { TArtist } from '@app/features/Artists/state';
import { TGenre } from '@app/features/Genres/state';
import { useTranslation } from 'react-i18next';
import { optionsStatus } from '@app/features/Projects/constants';
import { useFetchArtistsQuery } from '@app/features/Artists/hooks';
import { useFetchGenresQuery } from '@app/features/Genres/hooks';
import ImagePicker from '@app/components/ImagePicker';

type TFieldForm = {
  isEditProject?: boolean;
};
const FieldsForm: React.FC<TFieldForm> = ({ isEditProject }) => {
  const [search, updateSearch] = useState('');

  const { t } = useTranslation();

  const {
    data: artists,
    isFetching,
    isLoading,
  } = useFetchArtistsQuery({
    order_dir: 'asc',
    order_by: 'stage_name',
    ...(search && { search }),
  });
  const { data: genres } = useFetchGenresQuery({});

  const onSearch = useCallback(
    debounce((newValue: string) => updateSearch(newValue?.toLocaleLowerCase()), 1000),
    [],
  );

  return (
    <Grid xs={5} container direction="column">
      <>
        <Grid display={'flex'} alignItems={'center'}>
          <Field name="image">
            {(innerProps: FieldProps) => {
              return <ImagePicker {...innerProps} />;
            }}
          </Field>
        </Grid>
        <Field name={'genre'}>
          {(innerProps: FieldProps) => (
            <Autocomplete
              onChange={(_, value, reason) => {
                if (reason === 'clear') {
                  innerProps.form.setFieldValue('genre', {
                    name: '',
                    id: '',
                  });
                } else {
                  innerProps.form.setFieldValue('genre', {
                    name: value?.name,
                    id: value?.id,
                  });
                }
              }}
              getOptionLabel={option => option.name}
              options={genres || []}
              inputValue={innerProps.field.value.name}
              renderInput={params => (
                <TextInput
                  label={t('projects.projectGenre')}
                  onChangeCustom={(e: string) => {
                    innerProps.form.setFieldValue('genre', {
                      name: e,
                    });
                  }}
                  customError={(innerProps.form.errors.genre as unknown as TGenre)?.id}
                  {...params}
                  {...innerProps}
                />
              )}
              fullWidth
            />
          )}
        </Field>
        <Field name="name">
          {(innerProps: FieldProps) => {
            return <TextInput label={t('projects.projectName')} {...innerProps} />;
          }}
        </Field>
        {!isEditProject && (
          <Field name={'artist'}>
            {(innerProps: FieldProps) => {
              return (
                <Autocomplete
                  onChange={(_, value, reason) => {
                    if (reason === 'clear') {
                      onSearch('');
                      innerProps.form.setFieldValue('artist', {
                        name: '',
                        id: '',
                      });
                    } else {
                      innerProps.form.setFieldValue('artist', {
                        name: value?.simple_profile.stage_name,
                        id: value?.id,
                      });
                    }
                  }}
                  loading={isFetching || isLoading}
                  getOptionLabel={option =>
                    option.simple_profile ? option.simple_profile.stage_name : ''
                  }
                  renderOption={(props, option, state, ownerState) => {
                    return (
                      <Box component="li" {...props} key={option.id}>
                        {ownerState.getOptionLabel(option)}
                      </Box>
                    );
                  }}
                  options={artists?.data || []}
                  renderInput={params => {
                    return (
                      <TextInput
                        label={t('projects.artist')}
                        onChangeCustom={(e: string) => onSearch(e)}
                        customError={(innerProps.form.errors.artist as unknown as TArtist)?.id}
                        {...params}
                        {...innerProps}
                      />
                    );
                  }}
                  fullWidth
                />
              );
            }}
          </Field>
        )}
        <Field name="description">
          {(innerProps: FieldProps) => (
            <TextInput rows={3} multiline label={t('projects.description')} {...innerProps} />
          )}
        </Field>
        <Box mt={5} mb={2}>
          <Typography variant={'h6'}>Goal and deadline</Typography>
        </Box>
        <Field name="projectGoal">
          {(innerProps: FieldProps) => (
            <TextInput label={t('projects.projectGoal')} {...innerProps} />
          )}
        </Field>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name="goalAmount">
              {(innerProps: FieldProps) => (
                <TextInput label={t('projects.goalAmount')} {...innerProps} />
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="sharePercent">
              {(innerProps: FieldProps) => (
                <TextInput label={t('projects.projectOfCopyright')} {...innerProps} />
              )}
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name="durationDays">
              {(innerProps: FieldProps) => (
                <TextInput label={t('projects.CampaignOfDuration')} {...innerProps} />
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="status">
              {(innerProps: FieldProps) => (
                <Autocomplete
                  disableClearable={true}
                  disabled={!isEditProject}
                  inputValue={isEditProject ? innerProps.field.value : 'Draft'}
                  onChange={(_, value) => innerProps.form.setFieldValue('status', value)}
                  options={optionsStatus}
                  renderInput={params => (
                    <TextInput label={t('projects.status')} {...params} {...innerProps} />
                  )}
                  fullWidth
                />
              )}
            </Field>
          </Grid>
          <Box mt={3} mb={3} pl={2} width="100%">
            <Divider style={{ height: 1, width: '100%' }} light />
          </Box>
        </Grid>
      </>
    </Grid>
  );
};

export default FieldsForm;
