import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import store from '@app/state/store';
import { TAudio, TProject } from '@app/features/Projects/state/api';
import { TArtist } from '@app/features/Artists/state';

type AudioFile = {
  audio: File;
  sizeInMB: string;
  fileFormat: string;
};

type TPorjectState = {
  trimmedAudio?: TAudio;
  audioFile?: AudioFile;
  project: TProject | undefined;
};

const initialState: TPorjectState = {
  trimmedAudio: undefined,
  audioFile: undefined,
  project: undefined,
};

const slice = createSlice({
  name: 'projects',
  initialState: initialState,
  reducers: {
    projectByIdAdd: (state, action: PayloadAction<TProject | undefined>) => {
      state.project = action.payload;
    },
    addArtistToProject: (state, action: PayloadAction<TArtist>) => {
      state.project = { ...state.project!, artist: action.payload };
    },
    audioAdded: (state, action: PayloadAction<AudioFile>) => {
      state.audioFile = action.payload;
    },
    trimmedAudioAdded: (state, action: PayloadAction<TAudio>) => {
      state.trimmedAudio = action.payload;
    },
    trimmedAudioClear: (state, action: PayloadAction<undefined>) => {
      state.trimmedAudio = action.payload;
    },
    audioFileClear: (state, action: PayloadAction<undefined>) => {
      state.audioFile = action.payload;
    },
  },
  extraReducers: {},
});

store.injectReducer(slice.name, slice.reducer);

export default slice;
