import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@app/hooks';

function ProtectedRoute() {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return isAuthenticated || token ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
