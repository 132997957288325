import { Routes, Route, Navigate } from 'react-router-dom';
import LogIn from './LogIn';

export default function () {
  return (
    <Routes>
      <Route index element={<Navigate to="login" replace />} />;
      <Route path="login" element={<LogIn />} />
    </Routes>
  );
}
