import React, { useCallback, useState } from 'react';
import DataTable from '@app/components/DataTable';
import { Box, debounce, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { TListRequest } from '@app/types';
import { updateFilter } from '@app/utils';
import { GridColDef } from '@mui/x-data-grid';
import { useFetchPaymentsQuery } from '@app/features/Payments/hooks';

import RefundModal from '@app/features/Payments/components/RefundModal';
import SearchField from '@app/components/SearchField';

const PaymentsTab = () => {
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useFetchPaymentsQuery({
    page: filter.page,
    limit: filter.limit,
    ...(filter.search ? { search: filter.search || '' } : null),
  });

  const payments = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const columns: GridColDef[] = [
    {
      field: 'transaction_id',
      headerName: 'Transaction id',
      valueGetter: params => params?.value || '',
      minWidth: 300,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'fee',
      headerName: 'Fee',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'tax_amount',
      headerName: 'Tax Amount',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'royalty_percent',
      headerName: 'Royalty Percent',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'external_status',
      headerName: 'External Status',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      minWidth: 190,
      sortable: false,
    },
    {
      field: 'user',
      headerName: 'Role',
      valueGetter: params => params?.value?.role || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'user.role',
      headerName: 'Email',
      valueGetter: params => params?.row?.user?.email || '',
      headerAlign: 'left',
      align: 'left',
      minWidth: 230,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 170,
      align: 'left',
      renderCell: params => {
        if (
          params.row.payment_system === 'stripe' &&
          params.row.status === 'completed' &&
          !params.row.refund_id
        )
          return <RefundModal transId={params.row.transaction_id} />;
      },
    },
  ];
  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );
  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography mt={2} variant="h4">
            Payments
          </Typography>
        </Box>
        <SearchField placeholder="Payments" onChange={e => onSearch(e.target.value)} />
      </Stack>
      <DataTable
        columns={columns}
        rows={payments}
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        loading={isFetching}
        rowCount={itemCount}
        page={filter.page}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
    </>
  );
};

export default PaymentsTab;
