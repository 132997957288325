import { TextField, TextFieldProps } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';

type Props = TextFieldProps;

const SearchField = (props: Props) => {
  return (
    <TextField
      variant="outlined"
      label="Search"
      sx={{ width: 280 }}
      InputProps={{
        startAdornment: <SearchRounded color="disabled" />,
      }}
      {...props}
    />
  );
};

export default SearchField;
