import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as Logo } from '@assets/icons/logo.svg';

import {
  List,
  ListItemButton,
  styled,
  Theme,
  CSSObject,
  Typography,
  Box,
  SvgIcon,
} from '@mui/material';
import {
  VolumeMute,
  Subscriptions,
  KeyboardVoice,
  Headset,
  CreditCard,
  Settings,
} from '@mui/icons-material';
import { ReactElement } from 'react';
const drawerWidth = 248;
const closeDrawerWidth = 72;

type TNavLink = {
  icon: ReactElement;
  path: string;
  text: string;
  isActive?: boolean;
  superAdminRoute?: boolean;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  left: 5,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${closeDrawerWidth}px + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${closeDrawerWidth}px + 1px)`,
  },
});

const StyledList = styled(List, { shouldForwardProp: prop => prop !== 'open' })({
  '&& .MuiButtonBase-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
  '&& .Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&& .MuiListItemButton-root': {
    color: 'white',
  },
  '& .Mui-selected .selected': {
    top: '0px',
    position: 'absolute',
    width: '4px',
    height: '100%',
    backgroundColor: '#3ea832',
    borderRadius: '0px 2px 2px 0px',
  },
  '& .MuiList-root>li>a,': {
    color: 'white',
    fontWeight: 600,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiListItemIcon-root': {
        minWidth: '34px',
      },
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
      },
    }),
  }),
);

const navigationMenu: TNavLink[] = [
  {
    icon: <VolumeMute style={{ color: '#212121' }} />,
    path: 'projects',
    text: 'Projects',
  },
  {
    icon: <KeyboardVoice style={{ color: '#212121' }} />,
    path: 'artists',
    text: 'Artists',
  },
  {
    icon: <Headset style={{ color: '#212121' }} />,
    path: 'fans',
    text: 'Fans',
  },
  {
    icon: <Subscriptions style={{ color: '#212121' }} />,
    path: 'genres',
    text: 'Genres',
  },
  {
    icon: <CreditCard style={{ color: '#212121' }} />,
    path: 'payments',
    text: 'Payments',
  },
  {
    icon: <Settings style={{ color: '#212121' }} />,
    path: 'config',
    text: 'Config',
  },
];

const NavLink = ({ icon, text, path, isActive }: TNavLink) => {
  return (
    <li style={{ marginRight: 5, backgroundColor: isActive ? '#2196F314' : 'inherit' }}>
      <Link to={path} style={{ textDecoration: 'none' }}>
        <ListItemButton>
          <>
            {icon}
            <Typography color="black" fontSize={16} marginLeft={5}>
              {text}
            </Typography>
          </>
        </ListItemButton>
      </Link>
    </li>
  );
};

export default function Sidebar() {
  const { pathname } = useLocation();

  return (
    <Drawer variant="permanent" open={true}>
      <Box>
        <SvgIcon
          component={Logo}
          style={{ marginLeft: 24, marginTop: 32, width: 120 }}
          viewBox="0 0 120 24"
        />
      </Box>
      <StyledList>
        <Box mt={5}>
          {navigationMenu.map(el => {
            return <NavLink key={el.text} {...el} isActive={pathname.includes(el.path)} />;
          })}
        </Box>
      </StyledList>
    </Drawer>
  );
}
