import * as React from 'react';
import Box from '@mui/material/Box';
import { Link, Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { styled } from '@mui/material/styles';
import { Breadcrumbs, Typography } from '@mui/material';
import { useMemo } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export default function Layout() {
  const routes = [{ path: '/admin/artists/:userId', breadcrumb: 'View artist' }];

  const breadcrumbs = useBreadcrumbs(routes);

  const StyledBreadcrumbs = styled(Breadcrumbs)({
    fontSize: '18px',
    fontWight: 600,
    paddingBottom: '16px',
    '& a': {
      color: 'black',
      textDecoration: 'none',
      fontWight: 600,
      textTransform: 'capitalize',
    },
    '& .MuiBreadcrumbs-separator': {
      marginLeft: '5px',
      marginRight: '5px',
    },
    '& .MuiBreadcrumbs-separator svg': {
      fontSize: '20px',
    },
  });
  const preparedBreadCrumbs = useMemo(
    () => breadcrumbs.filter((_, index) => index > 1),
    [breadcrumbs],
  );

  return (
    <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
      <Sidebar />
      <Box component="main" sx={{ flex: 1, flexGrow: 1 }}>
        <Header />
        <Box sx={{ padding: '30px 40px' }}>
          <>
            <StyledBreadcrumbs separator={'/'}>
              {preparedBreadCrumbs.length > 1 &&
                preparedBreadCrumbs.map(({ breadcrumb, key }) => {
                  return (
                    <Link key={key} to={key}>
                      <Typography>{breadcrumb}</Typography>
                    </Link>
                  );
                })}
            </StyledBreadcrumbs>
            <Outlet />
          </>
        </Box>
      </Box>
    </Box>
  );
}
