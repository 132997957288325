import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Row from '@app/features/Artists/components/Row';
import { useFetchPaymentConfigQuery } from '@app/features/Payments/hooks';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextInput from '@app/components/TextInput';
import { updateConfigSchema } from '@app/features/Payments/schemas/updateConfig.schema';
import ChangeCommissionModal from '@app/features/Payments/components/ChangeCommissionModal';

const CommissionTab = () => {
  const [isEdit, setEdit] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { data, isLoading } = useFetchPaymentConfigQuery({});
  const onSubmit = () => {
    setModalOpen(true);
  };
  return (
    <Box>
      <Typography mb={2} variant="h4">
        Current commissions
      </Typography>
      <Formik
        initialValues={{
          payoutFee: '',
          paymentFee: '',
        }}
        validationSchema={updateConfigSchema}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            {isEdit ? (
              <Grid container xs={6}>
                <Field name="payoutFee">
                  {(innerProps: FieldProps) => {
                    return <TextInput label={'Withdraw commission'} {...innerProps} />;
                  }}
                </Field>
                <Field name="paymentFee">
                  {(innerProps: FieldProps) => {
                    return <TextInput label={'Contribution commission'} {...innerProps} />;
                  }}
                </Field>
              </Grid>
            ) : (
              <>
                <Row
                  label={'Withdraw commission'}
                  children={<Typography>{data?.payout_fee}%</Typography>}
                />
                <Row
                  label={'Contribution commission'}
                  children={<Typography>{data?.payment_fee}%</Typography>}
                />
              </>
            )}
            <Grid container xs={6}>
              <Box mt={3} mb={3} width="100%">
                <Divider style={{ height: 1, width: '100%' }} light />
              </Box>
              <Box display="flex" justifyContent="flex-end" width="100%">
                {isEdit ? (
                  <Box>
                    <Button onClick={() => setEdit(false)}>Cancel</Button>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </Box>
                ) : (
                  <Button
                    onClick={() => {
                      setEdit(true);
                      setFieldValue('payoutFee', data?.payout_fee);
                      setFieldValue('paymentFee', data?.payment_fee);
                    }}
                    disabled={isLoading}
                    variant="contained">
                    Edit Commission
                  </Button>
                )}
              </Box>
            </Grid>
            <ChangeCommissionModal
              values={values}
              open={isModalOpen}
              handleClose={() => setModalOpen(false)}
              closeEdit={() => setEdit(false)}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CommissionTab;
