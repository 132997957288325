import * as Yup from 'yup';
import { passwordSchema } from '@app/features/Fans/schemas/createFan.schema';

export const createNewArtistSchema = Yup.object().shape({
  stageName: Yup.string()
    .required('Stage name is required')
    .max(50, 'The field is limited to 50 symbols'),
  firstName: Yup.string().optional().nullable().max(50, 'The field is limited to 50 symbols'),
  lastName: Yup.string().optional().nullable().max(50, 'The field is limited to 50 symbols'),
  genres: Yup.array().min(1).max(3).required('Genre is required'),
  email: Yup.string().required('Email is required').email(),
  password: passwordSchema,
});
export const updateArtistSchema = Yup.object().shape({
  stageName: Yup.string()
    .required('Stage name is required')
    .max(50, 'The field is limited to 50 symbols'),
  firstName: Yup.string().optional().nullable().max(50, 'The field is limited to 50 symbols'),
  lastName: Yup.string().optional().nullable().max(50, 'The field is limited to 50 symbols'),
  genres: Yup.array().min(1).max(3).required('Genre is required'),
  email: Yup.string().required('Email is required').email(),
  verificationStatus: Yup.string().required('Status is required'),
  rejectVerificationReason: Yup.string()
    .max(100, 'The message is too long. Please make sure it contains up to 100 symbols')
    .when('verificationStatus', {
      is: (status: string) => status === 'rejected',
      then: schema => schema.required('Reject reason is required'),
    }),
});
