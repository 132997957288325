import React, { useCallback, useState } from 'react';
import DataTable from '@app/components/DataTable';
import SearchField from '@app/components/SearchField';
import { Link } from 'react-router-dom';
import { Box, Button, debounce, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { TListRequest } from '@app/types';
import { useTranslation } from 'react-i18next';
import { Columns } from './components/Columns';
import { useFetchArtistsQuery } from '@app/features/Artists/hooks';
import { useNavigate } from 'react-router';
import { onSort, updateFilter } from '@app/utils';
import CheckboxFilter from '@app/components/CheckboxFilter';

const Atists = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
    order_dir: 'asc',
    order_by: 'stage_name',
  });

  const { t } = useTranslation();

  const { data, isFetching } = useFetchArtistsQuery({
    page: filter.page,
    limit: filter.limit,
    order_dir: filter.order_dir,
    order_by: filter.order_by,
    ...(filter.search ? { search: filter.search || '' } : null),
    ...(filter.featured ? { featured: '1' || '' } : null),
    ...(filter.hasActiveProjects ? { has_actiive_projects: '1' || '' } : null),
    ...(filter.deleted ? { deleted: '1' || '' } : null),
    ...(filter.blocked ? { blocked: '1' || '' } : null),
  });

  const projects = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );

  // ?featured=1 // featured artists
  //     ?has_actiive_projects=1 // artists with active project
  //         ?deleted=1 // deleted artists
  //             ?blocked=1 // blocked artists

  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography variant="h4">Artists</Typography>
          <Link style={{ color: '#fff', textDecoration: 'none' }} to="create-artist">
            <Button variant="contained" color="primary">
              {t('artists.createArtist')}
            </Button>
          </Link>
        </Box>
        <SearchField placeholder="Artists" onChange={e => onSearch(e.target.value)} />
      </Stack>
      <Box>
        <CheckboxFilter
          field={'featured'}
          filter={filter}
          setFilter={setFilter}
          label={'Is featured'}
        />
        <CheckboxFilter
          field="hasActiveProjects"
          filter={filter}
          setFilter={setFilter}
          label={'Has active projects'}
        />
        <CheckboxFilter field={'deleted'} filter={filter} setFilter={setFilter} label={'Deleted'} />
        <CheckboxFilter field="blocked" filter={filter} setFilter={setFilter} label={'Blocked'} />
      </Box>
      <DataTable
        columns={Columns()}
        rows={projects}
        loading={isFetching}
        onCellClick={params => {
          if (params.field !== 'id') {
            navigate(`${params.id}`);
          }
        }}
        rowCount={itemCount}
        page={filter.page}
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        onSortModelChange={model => onSort(model, setFilter)}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
    </>
  );
};

export default Atists;
