import React from 'react';
import { Box, Button, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PaymentsTab from '@app/features/Payments/components/PaymentsTab';
import RefundsTab from '@app/features/Payments/components/RefundsTab';
import ProjectEarningsTab from '@app/features/Payments/components/ProjectEarningsTab';
import UserEarningsTab from '@app/features/Payments/components/UserEarningsTab';
import PayoutsTab from '@app/features/Payments/components/PayoutsTab';
import CommissionTab from '@app/features/Payments/components/CommissionTab';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/system';
import queryString from 'query-string';
import { useNavigate } from 'react-router';

const Payments = () => {
  const tab = queryString.parse(location.search).tab as string;

  const navigate = useNavigate();
  const [value, setValue] = React.useState(tab || '1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(`?tab=${newValue}`);
  };
  return (
    <Box>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="flex-end" flexDirection={'row'}>
          <Link style={{ color: '#fff', textDecoration: 'none' }} to="create-earning">
            <Button variant="contained" color="primary">
              Create Project Earning
            </Button>
          </Link>
        </Box>
      </Stack>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Payments" value="1" />
          <Tab label="Refunds" value="2" />
          <Tab label="Projects Earnings" value="3" />
          <Tab label="Users Earnings" value="4" />
          <Tab label="Payouts" value="5" />
          <Tab label="Commission" value="6" />
        </TabList>
        <TabPanel style={{ marginTop: 10, padding: 0 }} value="1">
          <PaymentsTab />
        </TabPanel>
        <TabPanel value="2">
          <RefundsTab />
        </TabPanel>
        <TabPanel value="3">
          <ProjectEarningsTab />
        </TabPanel>
        <TabPanel value="4">
          <UserEarningsTab />
        </TabPanel>
        <TabPanel value="5">
          <PayoutsTab />
        </TabPanel>
        <TabPanel value="6">
          <CommissionTab />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Payments;
