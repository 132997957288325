import React from 'react';
import { Box, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { formatMimeType } from '@app/utils';
import AudioPlayer from '@app/features/Projects/components/AudioPlayer';
import { useDispatch } from 'react-redux';
import { audioAdded } from '@app/features/Projects';
import useAppSelector from '@app/hooks/useAppSelector';
import { selectAudioFile } from '@app/features/Projects/state/selectors';
import { UploadFile } from '@mui/icons-material';

type UploadAudioProps = {
  projectId: string | number;
};

const UploadAudio: React.FC<UploadAudioProps> = ({ projectId }) => {
  const audioFile = useAppSelector(selectAudioFile);
  const dispatch = useDispatch();

  const handleChangeFile = (file: File) => {
    const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    dispatch(
      audioAdded({
        audio: file,
        sizeInMB: sizeInMB,
        fileFormat: formatMimeType(file.type),
      }),
    );
  };
  return (
    <Box mt={5}>
      {!audioFile ? (
        <>
          <FileUploader
            children={
              <Box
                style={{
                  borderColor: 'lightgray',
                  borderRadius: 4,
                  borderStyle: 'dashed',
                  width: 550,
                  height: 150,
                }}>
                <Box height={150} display="flex" justifyContent="center" alignItems="center">
                  <Box>
                    <UploadFile style={{ width: '100%' }} color="primary" />
                    <Box textAlign="center">
                      <Typography
                        display="inline"
                        style={{ textDecoration: 'underline' }}
                        color="primary">
                        Click to upload
                      </Typography>
                      <Typography display="inline"> or drag and drop</Typography>
                    </Box>
                    <Typography color="gray">
                      MP3, OGG, WAVE, file size no more than 50MB
                    </Typography>
                  </Box>
                </Box>
              </Box>
            }
            handleChange={handleChangeFile}
            name="file"
            types={['MP3', 'OGG', 'WAV']}
          />
        </>
      ) : (
        <AudioPlayer projectId={projectId} />
      )}
    </Box>
  );
};

export default UploadAudio;
