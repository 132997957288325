import React from 'react';
import { Box, Button, CircularProgress, Tab, Typography } from '@mui/material';
import { useFetchArtistQuery, useUnblockArtistMutation } from '@app/features/Artists/hooks';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import ArtistInfo from '@app/features/Artists/ArtistInfo';
import ViewChanges from '@app/features/Artists/components/ViewChanges';
import ArtistProjects from '@app/features/Artists/components/ArtistProjects';
import ArtistAndFanBlock from '@app/components/ArtistAndFanBlock';
import ArtistAndFanDelete from '@app/components/ArtistAndFanDelete';

const ArtistView = () => {
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: artist, isLoading } = useFetchArtistQuery(id, { skip: !id });
  const [unblock] = useUnblockArtistMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box mt={2} mb={2}>
              <Typography variant={'h6'}>{artist?.profile?.stage_name}</Typography>
            </Box>
            <Box>
              {artist?.is_deleted ? (
                <Typography>This artist deleted</Typography>
              ) : (
                <>
                  <ArtistAndFanDelete isArtist id={id!} />
                  {artist?.is_blocked ? (
                    <Button variant="contained" onClick={() => unblock(id!)}>
                      Unblock
                    </Button>
                  ) : (
                    <ArtistAndFanBlock isArtist id={id!} />
                  )}

                  <Button
                    style={{ marginRight: 10 }}
                    onClick={() => navigate('view-changes-history')}>
                    View Change History
                  </Button>
                  <Button
                    style={{ width: 143 }}
                    variant="contained"
                    onClick={() => navigate(`../update-artist?id=${id}`, { replace: true })}>
                    Edit Details
                  </Button>
                </>
              )}
            </Box>
          </Box>
          {artist?.profile?.verification_status ? (
            <ViewChanges
              artistId={id!}
              verificationStatuses={artist?.profile?.verification_status}
            />
          ) : null}
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Details" value="1" />
              <Tab label="Projects" value="2" />
            </TabList>
            <TabPanel style={{ marginTop: 10, padding: 0 }} value="1">
              <ArtistInfo artist={artist!} />
            </TabPanel>
            <TabPanel value="2">
              <ArtistProjects artistId={id!} />
            </TabPanel>
          </TabContext>
        </>
      )}
    </>
  );
};

export default ArtistView;
