import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useFetchChangesHistoryQuery } from '@app/features/Artists/hooks';
import { useParams } from 'react-router-dom';
import { TListRequest } from '@app/types';
import DataTable from '@app/components/DataTable';
import moment from 'moment';
import { TFieldsChanged } from '@app/features/Artists/state';
import { updateFilter } from '@app/utils';

const ViewChangeHistory = () => {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<TFieldsChanged[] | null>(null);
  const handleClickOpen = (values: TFieldsChanged[]) => {
    setOpen(true);
    setModalData(values);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useFetchChangesHistoryQuery({
    id,
    page: filter.page,
    limit: filter.limit,
    order_dir: filter.order_dir,
    order_by: filter.order_by,
  });

  const projects = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const columns: GridColDef[] = [
    {
      field: 'updated_at',
      headerName: 'Date of change',
      valueGetter: params => moment(params.row.updated_at as Date).format('MM/DD/YYYY'),
      flex: 0.5,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'new_value',
      headerName: 'Changed data',
      headerAlign: 'left',
      width: 500,
      valueGetter: params =>
        params.row.data.map((el: { field: any }) => el.field.split('_').join(' ')),
      align: 'left',
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Verification status',
      headerAlign: 'left',
      align: 'left',
      width: 150,
      sortable: false,
    },
    {
      field: 'comment',
      headerName: 'Reject comment',
      headerAlign: 'left',
      align: 'left',
      width: 250,

      sortable: false,
    },
    {
      field: 'admin',
      headerName: 'Changed by',
      valueGetter: params => params.row.simple_admin?.email || '',
      headerAlign: 'left',
      width: 200,
      align: 'left',
      sortable: false,
    },
  ];
  const columnsFields: GridColDef[] = [
    {
      field: 'field',
      headerName: 'Changed Data',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      valueGetter: params => `${params.row.field.split('_').join(' ')}`,
      sortable: false,
    },
    {
      field: 'new_value',
      headerName: 'New value',
      headerAlign: 'left',
      width: 300,
      align: 'left',
      sortable: false,
    },
    {
      field: 'old_value',
      headerName: 'Old Value',
      headerAlign: 'left',
      width: 300,
      align: 'left',
      sortable: false,
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        rows={projects}
        loading={isFetching}
        onCellClick={params => {
          handleClickOpen(params.row.data as TFieldsChanged[]);
        }}
        rowCount={itemCount}
        page={filter.page}
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Viewing Changes'}</DialogTitle>
        <DialogContent>
          <DataTable
            getRowId={row => `${row.field} ${row.old_value} ${row.new_value}`}
            hideFooter={true}
            columns={columnsFields}
            rows={modalData || []}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewChangeHistory;
