import { baseApi } from '@app/state';
import { prepareFormData } from '@app/utils';
import { TListRequest } from '@app/types';
export interface TGenre {
  id: number;
  name: string;
  image_url?: string;
  created_at: string;
  updated_at: string;
  status: string;
}
export interface TCreteRequest {
  name: string;
  image?: File;
}
export interface TUpdateRequest {
  id: string;
  name: string;
  image?: File;
}

export type TListResponse = TGenre[];

export const api = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Genres'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      fetchGenres: builder.query<TListResponse, TListRequest>({
        query: params => {
          return {
            url: 'admin/genres',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Genres'],
      }),
      createGenre: builder.mutation<TListResponse, TCreteRequest>({
        query: body => {
          return {
            url: 'admin/genres',
            method: 'POST',
            body: prepareFormData(body),
          };
        },
        invalidatesTags: ['Genres'],
      }),
      getGenre: builder.query<TGenre, string>({
        query: id => {
          return {
            url: `admin/genres/${id}`,
            method: 'GET',
          };
        },
      }),
      updateGenre: builder.mutation<TGenre, TUpdateRequest>({
        query: body => {
          return {
            url: `admin/genres/${body.id}`,
            method: 'POST',
            body: prepareFormData(body),
          };
        },
      }),
      archiveGenre: builder.mutation<any, string>({
        query: id => {
          return {
            url: `admin/genres/${id}/archive`,
            method: 'POST',
          };
        },
      }),
      restoreGenre: builder.mutation<any, string>({
        query: id => {
          return {
            url: `admin/genres/${id}/restore`,
            method: 'POST',
          };
        },
      }),
    }),
  });
