import { baseApi } from '@app/state';

export const api = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Config'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      fetchTermsAdmin: builder.query<{ terms: string }, any>({
        query: () => {
          return {
            url: 'admin/config/terms',
            method: 'GET',
          };
        },
        providesTags: ['Config'],
      }),
      updateTerms: builder.mutation<{ terms: string }, { terms: string }>({
        query: body => {
          return {
            url: 'admin/config/terms',
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['Config'],
      }),
    }),
  });
