import { Routes, Route } from 'react-router-dom';
import Fans from './Fans';
import FanDetails from './FanDetails';
import ViewFan from './ViewFan';

export default function () {
  return (
    <Routes>
      <Route index element={<Fans />} />
      <Route path="create-fan" element={<FanDetails />} />
      <Route path="edit-fan" element={<FanDetails />} />
      <Route path="view-fan" element={<ViewFan />} />
    </Routes>
  );
}
