import * as Yup from 'yup';

export const createNewProjectSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project name is required')
    .max(50, 'The field must contain up to 50 symbols'),
  description: Yup.string().optional().max(200, 'The field must contain up to 200 symbols'),
  artist: Yup.object()
    .shape({
      name: Yup.string(),
      id: Yup.string().required('Artist is required'),
    })
    .default(undefined)
    .required('Artist is required'),
  genre: Yup.object()
    .shape({
      name: Yup.string(),
      id: Yup.string().required('Genre is required'),
    })
    .default(undefined)
    .required('Genre is required'),
  sharePercent: Yup.number()
    .integer('Please make sure the portion of copyright is a whole number')
    .typeError('The song copyright portion be a number')
    .required('The song copyright portion is required')
    .min(1, 'The value must be between 1% - 100%')
    .max(100, 'The value must be between 1% - 100%'),
  durationDays: Yup.number()
    .integer('Please make sure the project duration is a whole number')
    .typeError('Campaign duration be a number')
    .required('Campaign duration is required')
    .min(1, 'Please make sure the project duration is between 1 and 365 days')
    .max(365, 'Please make sure the project duration is between 1 and 365 days'),
  projectGoal: Yup.string()
    .required('Project goal is required')
    .min(10, 'The project goal description must be at least 10 characters')
    .max(50, 'The field must contain up to 50 symbols'),
  goalAmount: Yup.number()
    .integer('Cents are not allowed')
    .typeError('Goal amount be a number')
    .required('Goal amount is required')
    .min(150, 'The value must be at least $150')
    .max(1000000, 'The value must not exceed $1,000,000.00'),
});
