import { api } from '../state';

export const {
  useFetchGenresQuery,
  useCreateGenreMutation,
  useGetGenreQuery,
  useUpdateGenreMutation,
  useArchiveGenreMutation,
  useRestoreGenreMutation,
} = api;
