import { Checkbox } from '@mui/material';
import React from 'react';
import { FieldProps } from 'formik';

interface IFieldSimpleProps extends Pick<FieldProps, 'field' | 'form'> {
  onChaneCustom?: () => void;
}
const CheckboxField: React.FC<IFieldSimpleProps> = ({ field, form, onChaneCustom }) => {
  return (
    <Checkbox
      onChange={e => {
        if (onChaneCustom) {
          onChaneCustom();
        }
        return form.setFieldValue(field.name, e.target.checked);
      }}
      checked={field.value}
    />
  );
};

export default CheckboxField;
