import { TArtist } from '@app/features/Artists/state';

export const getInitialValue = (artist: TArtist) => {
  return {
    stageName: artist?.profile?.stage_name,
    firstName: artist?.first_name,
    lastName: artist?.last_name,
    image: artist?.avatar_url,
    genres: artist?.genres,
    artistType: artist?.profile?.artist_type || 'solo',
    featured: artist?.is_featured || false,
    tiktokUrl: artist?.profile?.tiktok_url,
    spotifyUrl: artist?.profile?.spotify_url,
    instagramUrl: artist?.profile?.instagram_url,
    appleMusicUrl: artist?.profile?.apple_music_url,
    email: artist?.email,
    verificationStatus: artist?.verification_status || '',
    rejectVerificationReason: artist?.reject_verification_reason || '',
    password: '',
  };
};
