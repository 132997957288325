import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';

export const Columns = () => {
  const navigate = useNavigate();
  return [
    {
      field: 'stage_name',
      headerName: 'Artist',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: params => params.row.simple_profile?.stage_name || '',
      sortable: true,
    },
    {
      field: 'genres',
      headerName: 'Genres',
      headerAlign: 'left',
      align: 'left',
      width: 250,
      valueGetter: params => params.value.map((genre: { name: string }) => `${genre.name}`),
      sortable: false,
    },
    {
      field: 'verification_status',
      headerName: 'Verification status',
      headerAlign: 'left',
      align: 'left',
      width: 150,
      sortable: false,
    },
    {
      field: 'projects_count',
      headerName: 'Projects',
      headerAlign: 'left',
      align: 'left',
      width: 100,
      sortable: false,
    },
    {
      field: 'followers_count',
      headerName: 'Followers',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'id',
      headerName: 'Actions',
      sortable: false,
      width: 80,
      align: 'center',
      renderCell: ({ id }) => {
        return (
          <IconButton
            onClick={() => {
              navigate(`update-artist?id=${id}`);
            }}>
            <Edit />
          </IconButton>
        );
      },
    },
  ] as GridColDef[];
};
