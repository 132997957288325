import * as Yup from 'yup';
export const createEarningSchema = Yup.object().shape({
  amount: Yup.number()
    .min(5, 'The value must be at least $5')
    .required('Amount is required')
    .typeError('Only numbers are allowed in this field'),
  project: Yup.object()
    .shape({
      name: Yup.string(),
      id: Yup.string().required('Project is required'),
    })
    .default(undefined)
    .required('Project is required'),
});
