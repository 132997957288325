import React, { useState, useRef, useEffect, SetStateAction, Dispatch } from 'react';
import Slider from '@mui/material/Slider';
import { Box, IconButton, Typography } from '@mui/material';
import { PlayArrow, Stop } from '@mui/icons-material';
import useAppSelector from '@app/hooks/useAppSelector';
import { selectTrimmedAudio } from '@app/features/Projects/state/selectors';
import { MIN_AUDIO_TRIM } from '@app/features/Projects/constants';
import { formatTime } from '@app/utils';

interface AudioPlayerProps {
  audio: File | string;
  setStart: Dispatch<SetStateAction<number>>;
  start: number;
  setEnd: Dispatch<SetStateAction<number>>;
  end: number;
}
const AudioPlayer: React.FC<AudioPlayerProps> = ({ audio, start, setStart, setEnd, end }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [duration, setDuration] = useState<number | null>(0);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [finished, setFinished] = useState(false);
  const trimmedAudio = useAppSelector(selectTrimmedAudio);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = start;
    }
  }, [start]);

  useEffect(() => {
    console.log('-<', audio);
    if (audio) {
      if (audioRef.current) {
        if (trimmedAudio?.short_track_url) {
          audioRef.current.src = trimmedAudio?.short_track_url;
        } else if (typeof audio === 'string') {
          audioRef.current.src = audio;
        } else {
          audioRef.current.src = URL.createObjectURL(audio);
        }
        audioRef.current.load();
        audioRef.current.onloadeddata = function () {
          const audDuration = audioRef.current?.duration;
          console.log('-<>', audDuration);
          setDuration(audDuration as number);
        };
      }
    }
  }, [audio, trimmedAudio]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current && audioRef.current.currentTime >= end) {
        audioRef.current.pause();
        setPlaying(false);
        setFinished(true);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [end]);

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const newStart = newValue[0];
      const newEnd = newValue[1];
      if (newStart <= newEnd - MIN_AUDIO_TRIM) {
        setStart(newStart);
        setEnd(newEnd);
        setFinished(false);
      }
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (finished) {
        audioRef.current.currentTime = start;
        setFinished(false);
      }

      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <audio ref={audioRef} onTimeUpdate={handleTimeUpdate} preload="metadata" />
        <Box>
          <IconButton style={{ marginRight: 29, marginBottom: 7 }} onClick={handlePlayPause}>
            {playing ? <Stop color="error" /> : <PlayArrow color="primary" />}
          </IconButton>
        </Box>
        <Slider
          value={[start, end]}
          onChange={handleSliderChange}
          min={0}
          max={duration as number}
          step={1}
          valueLabelDisplay="auto"
          valueLabelFormat={value => `${value.toFixed()} s`}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ marginLeft: 60 }}>{formatTime(currentTime)}</Typography>
        <Typography>{formatTime(end)}</Typography>
      </Box>
    </Box>
  );
};

export default AudioPlayer;
