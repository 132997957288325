import React, { Dispatch, SetStateAction } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { updateFilter } from '@app/utils';
import { TListRequest } from '@app/types';

const CheckboxFilter = ({
  filter,
  field,
  setFilter,
  label,
}: {
  field: keyof TListRequest;
  filter: TListRequest;
  setFilter: Dispatch<SetStateAction<TListRequest>>;
  label: string;
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems={'center'}>
      <Checkbox
        value={filter.featured}
        onChange={(event, checked) => updateFilter(field, Boolean(checked), setFilter)}
      />
      <Typography>{label}</Typography>
    </Box>
  );
};

export default CheckboxFilter;
