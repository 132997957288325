import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link } from 'react-router-dom';

const RefundModal = ({ transId }: { transId: string }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const getLinkUrl = () => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'stage' ||
      !process.env.NODE_ENV
    ) {
      return `https://dashboard.stripe.com/test/payments/${transId}`;
    } else {
      return `https://dashboard.stripe.com/payments/${transId}`;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={() => handleClickOpen()}>Refund</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Refund</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            To refund the transaction, please indicate the transaction ID <strong>{transId}</strong>{' '}
            in your Stripe account. The refunded money amount will be transferred to the user's bank
            account.
          </DialogContentText>
          <DialogActions>
            <Link to={getLinkUrl()} target="_blank">
              <Button onClick={() => handleClose()}>Refund</Button>
            </Link>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RefundModal;
