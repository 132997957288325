import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  useDistributeEarningsMutation,
  useFetchEarningQuery,
  useLazyFetchProjectDistributionQuery,
} from '@app/features/Payments/hooks';
import queryString from 'query-string';
import Row from '@app/features/Artists/components/Row';
import { useNavigate } from 'react-router';
import showError from '@app/components/ErrorAlert';
import DataTable from '@app/components/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import { ProjectStatusesEnum } from '@app/features/Payments/enum/projectStatuses.enum';

const ViewProjectEarning = () => {
  const id = queryString.parse(location.search).id as string;
  const navigate = useNavigate();
  const { data: earning, isFetching } = useFetchEarningQuery(id);
  const [distribute, { isSuccess, isError, error, isLoading: isDistributeLoading }] =
    useDistributeEarningsMutation();
  const [fetchDistribution, { data: distributions, isLoading }] =
    useLazyFetchProjectDistributionQuery();
  const distributed = earning?.status === ProjectStatusesEnum.Distributed;

  useEffect(() => {
    if (isSuccess || distributed) {
      fetchDistribution({
        projectId: earning?.project.id as number,
        earningId: earning?.id as number,
      });
    }
  }, [isSuccess, distributed]);

  useEffect(() => {
    if (isError && error) {
      showError(error as string);
    }
  }, [isError, error]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      valueGetter: params => params?.value || '',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'project_id',
      headerName: 'Project',
      minWidth: 200,
      valueGetter: () => earning?.project.name || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'user',
      headerName: 'Admin Email',
      valueGetter: params => params?.value.email || '',
      minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
  ];

  return (
    <Box>
      {!isFetching && !isLoading ? (
        <>
          <Grid container xs={5}>
            <Typography mb={3} variant="h4">
              Distribute royalty
            </Typography>
            <Row
              label={'Project name'}
              children={<Typography>{earning?.project.name}</Typography>}
            />
            <Row label={'Project id'} children={<Typography>{earning?.project.id}</Typography>} />
            <Row label={'Amount, $'} children={<Typography>{earning?.amount}</Typography>} />
            {!isSuccess && !distributed && (
              <Grid mt={4} display="flex" justifyContent="flex-end">
                <Button onClick={() => navigate('../../payments?tab=3')} style={{ marginTop: 10 }}>
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    distribute({
                      projectId: earning?.project.id as number,
                      earningId: earning?.id as number,
                    })
                  }
                  style={{ width: 190, height: 38, marginLeft: 10, marginTop: 10 }}
                  variant="contained">
                  {isDistributeLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    'Distribute Royalty'
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
          <Box mt={4}>
            {distributions && <DataTable hideFooter columns={columns} rows={distributions || []} />}
          </Box>
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default ViewProjectEarning;
