import { baseApi } from '@app/state';

export type TLoginRequest = {
  email: string;
  password: string;
};

export type TLoginResponse = {
  token: string;
  refresh_token: string;
};

export const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<TLoginResponse, TLoginRequest>({
      query: credentials => ({
        url: 'admin/login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});
