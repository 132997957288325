import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import TextInput from '@app/components/TextInput';
import { useTranslation } from 'react-i18next';
import { createGenreSchema } from '@app/features/Genres/schemas/createGenreSchema';
import {
  useArchiveGenreMutation,
  useCreateGenreMutation,
  useGetGenreQuery,
  useRestoreGenreMutation,
  useUpdateGenreMutation,
} from '@app/features/Genres/hooks';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import ImagePicker from '@app/components/ImagePicker';
import { TCreteRequest, TUpdateRequest } from '@app/features/Genres/state';

const GenreDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const id = queryString.parse(location.search).id as string;

  const [createGenre, { isSuccess: isCreateSuccess }] = useCreateGenreMutation();
  const [updateGenre, { isSuccess: isUpdateSuccess }] = useUpdateGenreMutation();
  const { data: genre, isLoading } = useGetGenreQuery(id, { skip: !id });
  const [archiveGenre, { isSuccess: isArchiveSuccess }] = useArchiveGenreMutation();
  const [restoreGenre, { isSuccess: isRestoreSuccess }] = useRestoreGenreMutation();

  const onHandleSubmit = (values: FormikValues) => {
    if (id) {
      updateGenre({
        id,
        name: values.name,
        ...(values.image && { image: values.image }),
      } as TUpdateRequest);
    } else {
      createGenre({
        name: values.name,
        ...(values.image && { image: values.image }),
      } as TCreteRequest);
    }
  };

  useEffect(() => {
    if (isArchiveSuccess) {
      navigate(-1);
    }
  }, [isArchiveSuccess]);

  useEffect(() => {
    if (isRestoreSuccess) {
      navigate(-1);
    }
  }, [isRestoreSuccess]);

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess) {
      navigate(-1);
    }
  }, [isUpdateSuccess, isCreateSuccess]);

  return (
    <Box>
      {!isLoading ? (
        <Formik
          initialValues={{
            name: genre?.name,
            image: genre?.image_url,
          }}
          validationSchema={createGenreSchema}
          onSubmit={onHandleSubmit}>
          <Form>
            <Grid xs={6} container direction="column">
              <Field name="image">
                {(innerProps: FieldProps) => {
                  return <ImagePicker {...innerProps} />;
                }}
              </Field>
              <Field name="name">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('genres.genreName')} {...innerProps} />;
                }}
              </Field>
              {id && (
                <Box>
                  {genre?.status === 'archived' ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        restoreGenre(id);
                      }}>
                      Restore
                    </Button>
                  ) : (
                    <Button
                      color="error"
                      onClick={() => {
                        archiveGenre(id);
                      }}>
                      Archive
                    </Button>
                  )}
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => navigate(-1)} style={{ marginTop: 10 }}>
                  Cancel
                </Button>
                <Button type="submit" style={{ marginLeft: 10, marginTop: 10 }} variant="contained">
                  Save
                </Button>
              </Box>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Box>
  );
};

export default GenreDetails;
