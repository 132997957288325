import React, { useCallback, useState } from 'react';
import { Autocomplete, Box, Button, debounce, Grid, Typography } from '@mui/material';
import TextInput from '@app/components/TextInput';
import { useNavigate } from 'react-router';
import { createEarningSchema } from '@app/features/Payments/schemas/createEarning.schema';
import { useFetchProjectsQuery } from '@app/features/Projects/hooks';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { TProject } from '@app/features/Projects';
import { useTranslation } from 'react-i18next';
import { useCreateEarningMutation } from '@app/features/Payments/hooks';
import showError from '@app/components/ErrorAlert';

const CreateEarning = () => {
  const [search, updateSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isFetching } = useFetchProjectsQuery({
    status: 'completed',
    ...(search ? { search: search || '' } : null),
  });
  const [createEarning] = useCreateEarningMutation();
  const onSearch = useCallback(
    debounce((newValue: string) => updateSearch(newValue?.toLocaleLowerCase()), 1000),
    [],
  );
  const onHandleSubmit = (values: FormikValues) => {
    createEarning({
      id: values.project.id,
      amount: values.amount,
    })
      .unwrap()
      .then(data => {
        navigate(`../view-project-earnings?id=${data.id}`);
      })
      .catch(showError);
  };
  return (
    <Box>
      <Formik
        initialValues={{
          amount: '',
          project: {
            name: '',
            id: '',
          },
        }}
        validationSchema={createEarningSchema}
        onSubmit={onHandleSubmit}>
        <Form>
          <Typography variant={'h4'}>Create Earning</Typography>
          <Grid mt={2} xs={5} container direction="column">
            <Field name="amount">
              {(innerProps: FieldProps) => {
                return <TextInput label={'Amount'} {...innerProps} />;
              }}
            </Field>
            <Field name="project">
              {(innerProps: FieldProps) => {
                return (
                  <Autocomplete
                    onChange={(_, value, reason) => {
                      if (reason === 'clear') {
                        onSearch('');
                        innerProps.form.setFieldValue('project', {
                          name: '',
                          id: '',
                        });
                      } else {
                        innerProps.form.setFieldValue('project', {
                          name: value?.name,
                          id: value?.id,
                        });
                      }
                    }}
                    loading={isFetching}
                    onBlur={() => {
                      onSearch('');
                    }}
                    getOptionLabel={option => option.name || ''}
                    renderOption={(props, option, state, ownerState) => {
                      return (
                        <Box component="li" {...props} key={option.id}>
                          {ownerState.getOptionLabel(option)}
                        </Box>
                      );
                    }}
                    options={data?.data || []}
                    renderInput={params => {
                      return (
                        <TextInput
                          label={t('projects.project')}
                          onChangeCustom={(e: string) => onSearch(e)}
                          customError={(innerProps.form.errors.project as unknown as TProject)?.id}
                          {...params}
                          {...innerProps}
                        />
                      );
                    }}
                    fullWidth
                  />
                );
              }}
            </Field>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={() => navigate(-1)} style={{ marginTop: 10 }}>
                Cancel
              </Button>
              <Button type="submit" style={{ marginLeft: 10, marginTop: 10 }} variant="contained">
                Create
              </Button>
            </Box>
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
};

export default CreateEarning;
