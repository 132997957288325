import { Routes, Route } from 'react-router-dom';
import Projects from './Projects';
import NewProject from './NewProject';
import EditProject from './EditProject';
import ViewProject from './ViewProject';
import AddMusic from './AddMusic';

export default function () {
  return (
    <Routes>
      <Route index element={<Projects />} />
      <Route path="create-new-project" element={<NewProject />}></Route>
      <Route path="create-new-project/add-music" element={<AddMusic />} />
      <Route path="edit-project" element={<EditProject />} />
      <Route path="view-project" element={<ViewProject />} />
    </Routes>
  );
}
