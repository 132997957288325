import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import TextInput from '@app/components/TextInput';
import { TProjectRejectRequest } from '@app/features/Projects';

const AcceptModal = ({
  id,
  title = 'Please leave a comment (optional)',
  onPressCallback,
}: {
  id: string;
  title?: string;
  onPressCallback?: (params: TProjectRejectRequest) => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (values: FormikValues) => {
    if (onPressCallback) {
      onPressCallback({
        id,
        ...(values.comment && { comment: values.comment }),
      } as TProjectRejectRequest);
    }
    handleClose();
  };
  return (
    <>
      <Button onClick={() => handleClickOpen()}>Accept</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Accepting Project'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{title}</DialogContentText>
          <Formik
            initialValues={{
              comment: '',
            }}
            onSubmit={handleSubmit}>
            <Form>
              <Field name="comment">
                {(innerProps: FieldProps) => (
                  <TextInput multiline label={'Comment'} {...innerProps} />
                )}
              </Field>
              <DialogActions>
                <Button type="submit">Accept</Button>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AcceptModal;
