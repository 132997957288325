import { Routes, Route } from 'react-router-dom';
import Payments from '@app/features/Payments/Payments';
import CreateEarning from '@app/features/Payments/CreateEarning';
import ViewProjectEarning from '@app/features/Payments/ViewProjectEarning';

export default function () {
  return (
    <Routes>
      <Route index element={<Payments />} />
      <Route path="create-earning" element={<CreateEarning />} />
      <Route path="view-project-earnings" element={<ViewProjectEarning />} />
    </Routes>
  );
}
