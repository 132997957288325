import React, { useCallback, useState } from 'react';
import DataTable from '@app/components/DataTable';
import { Link } from 'react-router-dom';
import { Box, Button, debounce, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Columns } from './components/Columns';
import { useFetchFansQuery } from '@app/features/Fans/hooks';
import { updateFilter } from '@app/utils';
import { useNavigate } from 'react-router';
import SearchField from '@app/components/SearchField';
import { TListRequest } from '@app/types';
import CheckboxFilter from '@app/components/CheckboxFilter';

const Fans = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { t } = useTranslation();

  const { data, isFetching } = useFetchFansQuery({
    page: filter.page,
    limit: filter.limit,
    ...(filter.search ? { search: filter.search || '' } : null),
    ...(filter.deleted ? { deleted: '1' || '' } : null),
    ...(filter.blocked ? { blocked: '1' || '' } : null),
  });

  const projects = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;
  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );
  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography variant="h4">Fans</Typography>
          <Link style={{ color: '#fff', textDecoration: 'none' }} to="create-fan">
            <Button variant="contained" color="primary">
              {t('fans.createFan')}
            </Button>
          </Link>
        </Box>
        <SearchField placeholder="Fans" onChange={e => onSearch(e.target.value)} />
      </Stack>
      <Box>
        <CheckboxFilter field={'deleted'} filter={filter} setFilter={setFilter} label={'Deleted'} />
        <CheckboxFilter field="blocked" filter={filter} setFilter={setFilter} label={'Blocked'} />
      </Box>
      <DataTable
        columns={Columns()}
        rows={projects}
        onCellClick={params => {
          if (params.field !== 'id') {
            navigate(`view-fan?id=${params.id}`);
          }
        }}
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        loading={isFetching}
        rowCount={itemCount}
        page={filter.page}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
    </>
  );
};

export default Fans;
