import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import TextInput from '@app/components/TextInput';
import { blockArtistSchema } from '@app/features/Artists/schemas/blockArtist.schema';
import { useDeleteArtistMutation } from '@app/features/Artists/hooks';
import { useDeleteFanMutation } from '@app/features/Fans/hooks';
const ArtistAndFanDelete = ({ isArtist, id }: { isArtist: boolean; id: string }) => {
  const [open, setOpen] = useState(false);
  const [deleteArtist, { isSuccess: isArtistDeleted, isLoading: isDeleteArtistLoading }] =
    useDeleteArtistMutation();
  const [deleteFan, { isSuccess: isFanDeleted, isLoading: isDeleteFanLoading }] =
    useDeleteFanMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: FormikValues) => {
    if (isArtist) {
      deleteArtist({
        id,
        reason: values.reason,
      });
    } else {
      deleteFan({
        id,
        reason: values.reason,
      });
    }
  };
  useEffect(() => {
    if (isArtistDeleted || isFanDeleted) {
      handleClose();
    }
  }, [isArtistDeleted, isFanDeleted]);

  return (
    <>
      <Button onClick={() => handleClickOpen()} color="error">
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            you want to delete {isArtist ? 'artist' : 'fan'}
          </DialogContentText>
          <Formik
            validationSchema={blockArtistSchema}
            initialValues={{
              reason: '',
            }}
            onSubmit={handleSubmit}>
            <Form>
              <Field name="reason">
                {(innerProps: FieldProps) => (
                  <TextInput multiline label={'Reason'} {...innerProps} />
                )}
              </Field>
              <DialogActions>
                <Button type="submit">
                  {isDeleteFanLoading || isDeleteArtistLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Delete'
                  )}
                </Button>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ArtistAndFanDelete;
