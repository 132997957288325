import React, { useCallback, useState } from 'react';
import DataTable from '@app/components/DataTable';
import { Box, debounce, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { TListRequest } from '@app/types';
import { updateFilter } from '@app/utils';
import { GridColDef } from '@mui/x-data-grid';
import { useFetchRefundsQuery } from '@app/features/Payments/hooks';
import SearchField from '@app/components/SearchField';

const RefundsTab = () => {
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useFetchRefundsQuery({
    page: filter.page,
    limit: filter.limit,
    ...(filter.search ? { search: filter.search || '' } : null),
  });

  const refunds = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const columns: GridColDef[] = [
    {
      field: 'transaction_id',
      headerName: 'Transaction Id',
      valueGetter: params => params?.value || '',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'project_id',
      headerName: 'Project Id',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'user',
      headerName: 'Role',
      valueGetter: params => params?.value?.role || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'user.role',
      headerName: 'Email',
      valueGetter: params => params?.row?.user?.email || '',
      headerAlign: 'left',
      align: 'left',
      minWidth: 230,
      sortable: false,
    },
    {
      field: 'reason_type',
      headerName: 'Reason Type',
      minWidth: 170,
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
  ];
  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );
  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography variant="h4">Refunds</Typography>
        </Box>
        <SearchField placeholder="Refunds" onChange={e => onSearch(e.target.value)} />
      </Stack>
      <DataTable
        columns={columns}
        rows={refunds}
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        loading={isFetching}
        rowCount={itemCount}
        page={filter.page}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
    </>
  );
};

export default RefundsTab;
