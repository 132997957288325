import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useRejectProjectMutation } from '@app/features/Projects/hooks';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { rejectProjectSchema } from '@app/features/Projects/schemas/rejectProject.schema';
import TextInput from '@app/components/TextInput';
import { TProjectRejectRequest } from '@app/features/Projects';

const RejectModal = ({
  id,
  title = 'Are you sure you want to reject the project?',
  onPressCallback,
  mainTitle = 'Rejecting Project',
}: {
  id: string;
  title?: string;
  mainTitle?: string;
  onPressCallback?: (params: TProjectRejectRequest) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [rejectProject] = useRejectProjectMutation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (values: FormikValues) => {
    if (onPressCallback) {
      return onPressCallback({
        id,
        comment: values.comment,
      });
    } else {
      rejectProject({
        id,
        comment: values.comment,
      });
    }
  };
  return (
    <>
      <Button onClick={() => handleClickOpen()} color="error">
        Reject
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{mainTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{title}</DialogContentText>
          <Formik
            validationSchema={rejectProjectSchema}
            initialValues={{
              comment: '',
            }}
            onSubmit={handleSubmit}>
            <Form>
              <Field name="comment">
                {(innerProps: FieldProps) => (
                  <TextInput multiline label={'Comment'} {...innerProps} />
                )}
              </Field>
              <DialogActions>
                <Button type="submit">Reject</Button>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RejectModal;
