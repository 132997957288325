import { baseApi } from '@app/state';
import { TGenre } from '@app/features/Genres/state';
import { prepareFormData } from '@app/utils';
import { VerificationStatusesEnum } from '@app/features/Artists/enum/verificationStatuses.enum';
import { TProjectRejectRequest } from '@app/features/Projects';

export type TListRequest = {
  id?: string;
  featured?: string;
  page?: number;
  limit?: number;
  search?: string;
  order_by?: string;
  order_dir?: string;
  per_page?: number;
};

export type TFieldsChanged = {
  id?: string;
  field?: string;
  new_value?: string;
  old_value?: string;
};

export type TVerificationStatus = {
  id: number;
  admin_id?: number;
  artist_id: number;
  comment?: string;
  type: VerificationStatusesEnum;
  data: TFieldsChanged[];
};

export type TArtistProfile = {
  stage_name: string;
  artist_type: string;
  apple_music_url?: string;
  instagram_url?: string;
  spotify_url?: string;
  tiktok_url?: string;
  verification_status: TVerificationStatus;
};
export type TArtistRequest = {
  id?: string;
  stage_name: string;
  first_name?: string;
  last_name?: string;
  image?: File;
  genres: number[];
  artist_type?: string;
  featured?: boolean;
  tiktok_url?: string;
  spotify_url?: string;
  instagram_url?: string;
  apple_music_url?: string;
  verificationStatus?: string;
  rejectReason?: string;
  email: string;
  password: string;
};

export type TUploadAvatar = {
  id?: string;
  file: File;
};
export type TStipePaymentObject = {
  is_document_verified?: boolean;
  is_onboarding_passed?: boolean;
};

export interface TArtist {
  id: number;
  genres: TGenre[];
  email: string;
  email_verified_at: any;
  created_at: string;
  updated_at: string;
  last_activity_at: any;
  is_featured?: boolean;
  language: string;
  followers_count: number;
  avatar_url?: string;
  first_name?: string;
  last_name?: string;
  role: string;
  simple_profile: TArtistProfile;
  profile?: TArtistProfile;
  is_blocked?: boolean;
  is_deleted?: boolean;
  verification_status?: string;
  reject_verification_reason?: string;
  stripe_payment_profile?: TStipePaymentObject;
}

export type TListResponse = {
  current_page: number;
  data: TArtist[];
  first_page_url: string;
  from: number;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: any;
  to: number;
  total: number;
};

export const api = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Artists'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      fetchArtists: builder.query<TListResponse, TListRequest>({
        query: params => {
          return {
            url: 'admin/artists',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Artists'],
      }),
      createArtist: builder.mutation<TArtist, TArtistRequest>({
        query: body => {
          return {
            url: 'admin/artists',
            method: 'POST',
            body: body,
          };
        },
        invalidatesTags: ['Artists'],
      }),
      featureArtist: builder.mutation<TArtist, string>({
        query: id => {
          return {
            url: `admin/artists/${id}/feature`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Artists'],
      }),
      fetchArtist: builder.query<TArtist, string | number | undefined>({
        query: id => {
          return {
            url: `admin/artists/${id!}`,
            method: 'GET',
          };
        },
        providesTags: ['Artists'],
      }),
      updateArtist: builder.mutation<TArtist, TArtistRequest>({
        query: body => {
          return {
            url: `admin/artists/${body.id!}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['Artists'],
      }),
      uploadAvatar: builder.mutation<TArtist, TUploadAvatar>({
        query: body => {
          return {
            url: `admin/artists/${body.id!}/avatar`,
            method: 'POST',
            body: prepareFormData(body),
          };
        },
        invalidatesTags: ['Artists'],
      }),
      approveChanges: builder.mutation<TArtist, string>({
        query: id => {
          return {
            url: `admin/artists/${id}/profile/approve`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Artists'],
      }),
      rejectChanges: builder.mutation<any, TProjectRejectRequest>({
        query: body => {
          return {
            url: `admin/artists/${body.id}/profile/reject`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Artists'],
      }),
      fetchChangesHistory: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: `admin/artists/${params.id}/profile/verifications`,
            method: 'GET',
            params,
          };
        },
        providesTags: ['Artists'],
      }),
      blockArtist: builder.mutation<any, { id: string; reason: string }>({
        query: body => {
          return {
            url: `admin/artists/${body.id}/block`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Artists'],
      }),
      unblockArtist: builder.mutation<any, string>({
        query: id => {
          return {
            url: `admin/artists/${id}/unblock`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Artists'],
      }),
      deleteArtist: builder.mutation<any, { id: string; reason: string }>({
        query: body => {
          return {
            url: `admin/artists/${body.id}`,
            method: 'DELETE',
            body,
          };
        },
        invalidatesTags: ['Artists'],
      }),
    }),
  });
