import React, { useState } from 'react';
import DataTable from '@app/components/DataTable';
import { useFetchContributorsQuery } from '@app/features/Projects/hooks';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { updateFilter } from '@app/utils';
import { TListRequest } from '@app/types';
const Contracts = ({ id }: { id: string }) => {
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useFetchContributorsQuery({
    id,
    page: filter.page,
    limit: filter.limit,
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'User',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      valueGetter: params => {
        if (params.row?.last_name && params.row.last_name) {
          return `${params.row?.first_name} ${params.row?.last_name}`;
        } else {
          return `${params.row?.email}`;
        }
      },
      sortable: true,
    },
    {
      field: 'role',
      headerName: 'Role',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'share_percent',
      headerName: 'Royalty share, %',
      headerAlign: 'left',
      width: 200,
      align: 'left',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Actions',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: params => {
        return (
          <>
            {params.row?.documents?.length ? (
              <IconButton
                onClick={() =>
                  window.open(params.row.documents[0].document_url as string, '_blank')
                }>
                <Download />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
  ];

  const contracts = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  return (
    <DataTable
      onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
      pageSize={pageSize}
      rowCount={itemCount}
      loading={isFetching}
      columns={columns}
      page={filter.page}
      rows={contracts}
      onPageChange={page => updateFilter('page', page, setFilter)}
    />
  );
};
export default Contracts;
