import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { CheckCircle, Delete, Download, PlayArrow, Stop, UploadFile } from '@mui/icons-material';
import AudioTrim from '@app/features/Projects/components/AudioTrim';
import {
  useAttachAudioMutation,
  useTrimAudioMutation,
  useVerifyAudioMutation,
  useTrimExistingAudioMutation,
} from '@app/features/Projects/hooks';
import { useNavigate } from 'react-router';
import { downloadResource, formatTime } from '@app/utils';
import useAppSelector from '@app/hooks/useAppSelector';
import isEmpty from 'lodash.isempty';
import {
  selectAudioFile,
  selectProject,
  selectTrimmedAudio,
} from '@app/features/Projects/state/selectors';
import ReactJson from 'react-json-view';
import showError from '@app/components/ErrorAlert';
import { useDispatch } from 'react-redux';
import { audioFileClear } from '@app/features/Projects';
import { MIN_AUDIO_TRIM } from '@app/features/Projects/constants';
import Slider from '@mui/material/Slider';
interface AudioPlayerProps {
  projectId: string | number;
  isEditProject?: boolean;
}
const AudioPlayer: React.FC<AudioPlayerProps> = ({ projectId, isEditProject }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useAppSelector(selectProject);
  const [isPlaying, setIsPlaying] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(MIN_AUDIO_TRIM);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [statusJson, setStatusJson] = useState({});
  const audioFile = useAppSelector(selectAudioFile);
  const trimmedAudio = useAppSelector(selectTrimmedAudio);
  const [trimAudio, { isLoading: isTrimLoading }] = useTrimAudioMutation();
  const [trimExistingAudio, { isLoading: isTrimExcitingLoading }] = useTrimExistingAudioMutation();
  const [verifyAudio, { isLoading: isVerifyLoading }] = useVerifyAudioMutation();
  const [attachMedia, { isSuccess }] = useAttachAudioMutation();

  useEffect(() => {
    if (audioRef.current) {
      if (isEditProject && project && project.media) {
        audioRef.current.src = project.media.full_track_url;
        audioRef.current.load();
      } else if (!isEditProject && audioFile?.audio) {
        audioRef.current.src = URL.createObjectURL(audioFile?.audio);
        audioRef.current.load();
      }
    }
  }, [audioFile?.audio, project]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/admin/projects');
    }
  }, [navigate, isSuccess]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (newTime: number) => {
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };
  const onTrimClick = () => {
    if (isEditProject && !audioFile?.audio) {
      trimExistingAudio({
        from: start,
        duration: +end - +start,
        project_id: projectId,
      });
    } else {
      trimAudio({
        from: start,
        duration: +end - +start,
        file: audioFile!.audio,
        project_id: projectId,
      });
    }
    setStart(0);
  };

  const onVerifyAudioClick = () => {
    verifyAudio({
      from: +start,
      project_id: projectId,
    })
      .unwrap()
      .then(data => setStatusJson(data as object))
      .catch(showError);
  };
  const onSaveClick = () => {
    attachMedia({
      project_id: projectId,
      media_id: trimmedAudio!.id,
    });
  };

  return (
    <Grid direction="column" container xs={5}>
      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <Box mt={2} mb={2}>
        <Typography variant={'h6'}>Music</Typography>
      </Box>

      <Grid display="flex" justifyContent="space-between" item xs={6} ml={1}>
        <Box display="flex" alignItems="center">
          <UploadFile color="primary" />
          <Box ml={2}>
            <Typography>{audioFile?.audio.name || 'audio'}</Typography>
            {!isEditProject && (
              <>
                <Typography fontSize={14} display="inline" color="gray">
                  {audioFile?.sizeInMB} MB <>&#xB7;</>
                </Typography>
                <Typography fontSize={14} display="inline" color="gray">
                  {' '}
                  Complete
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box
          width={isEditProject ? 80 : 120}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Download
            onClick={() =>
              downloadResource(
                project?.media?.full_track_url ||
                  URL.createObjectURL(audioFile?.audio as unknown as MediaSource),
                audioFile?.audio.name || 'audio.mp3',
              )
            }
            style={{ color: 'gray' }}
          />
          {!isEditProject && (
            <Delete onClick={() => dispatch(audioFileClear(undefined))} style={{ color: 'gray' }} />
          )}
          <CheckCircle color="success" />
        </Box>
      </Grid>

      <Grid container>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Box display={'flex'} alignItems="center">
              <IconButton style={{ marginRight: 29, marginBottom: 7 }} onClick={togglePlay}>
                {isPlaying ? <Stop color="error" /> : <PlayArrow color="primary" />}
              </IconButton>
              <Box width="100%">
                <Slider
                  aria-label="time-indicator"
                  value={currentTime}
                  min={0}
                  step={1}
                  max={duration}
                  onChange={(_, value) => handleSeek(value as number)}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography style={{ marginLeft: 60 }}>{formatTime(currentTime)}</Typography>
              <Typography>{formatTime(duration)}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Typography variant={'h6'}>Fragment</Typography>
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <AudioTrim
              end={end}
              setEnd={setEnd}
              setStart={setStart}
              start={start}
              audio={((audioFile && audioFile?.audio) as File) || project?.media?.short_track_url}
            />
            <Box display="flex" justifyContent="flex-end" mt={5}>
              {/*/TODO: add for edit project*/}
              {isEditProject && (
                <Button
                  style={{ width: 240, marginRight: 15 }}
                  onClick={() => onVerifyAudioClick()}
                  variant="outlined">
                  {isVerifyLoading ? (
                    <CircularProgress size={20} color={'inherit'} />
                  ) : (
                    'Verify Music Ownership'
                  )}
                </Button>
              )}
              <Button style={{ width: 130 }} onClick={() => onTrimClick()} variant="outlined">
                {isTrimLoading || isTrimExcitingLoading ? (
                  <CircularProgress size={20} color={'inherit'} />
                ) : (
                  'Trim Music'
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2}>
        {!isEmpty(statusJson) && (
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            src={statusJson}
          />
        )}
      </Box>
      <Divider style={{ marginTop: 32, marginBottom: 32, height: 1, width: '100%' }} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ marginRight: 15 }}
          onClick={() => navigate('../../projects', { replace: true })}>
          Cancel
        </Button>
        {trimmedAudio && (
          <Button onClick={() => onSaveClick()} variant="contained">
            Save and Finish
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default AudioPlayer;
