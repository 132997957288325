import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DataTable from '@app/components/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import { TVerificationStatus } from '@app/features/Artists/state';
import RejectModal from '@app/components/RejectModal';
import { TProjectRejectRequest } from '@app/features/Projects';
import { useApproveChangesMutation, useRejectChangesMutation } from '@app/features/Artists/hooks';

const ViewChanges = ({
  artistId,
  verificationStatuses,
}: {
  artistId: string;
  verificationStatuses: TVerificationStatus;
}) => {
  const [open, setOpen] = useState(false);
  const [approve] = useApproveChangesMutation();
  const [rejectProject] = useRejectChangesMutation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectClick = (values: TProjectRejectRequest) => {
    rejectProject(values);
    handleClose();
  };

  const columns: GridColDef[] = [
    {
      field: 'field',
      headerName: 'Changed Data',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      valueGetter: params => `${params.row.field.split('_').join(' ')}`,
      sortable: false,
    },
    {
      field: 'new_value',
      headerName: 'New value',
      headerAlign: 'left',
      width: 300,
      align: 'left',
      sortable: false,
    },
    {
      field: 'old_value',
      headerName: 'Old Value',
      headerAlign: 'left',
      width: 300,
      align: 'left',
      sortable: false,
    },
  ];
  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Alert severity="error">
          This artist has unconfirmed profile changes click "View" to view them
        </Alert>
        <Button
          onClick={() => handleClickOpen()}
          style={{ width: 137, height: 42 }}
          variant="contained">
          View
        </Button>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Viewing Changes'}</DialogTitle>
        <DialogContent>
          <DataTable
            getRowId={row => `${row.field} ${row.old_value} ${row.new_value}`}
            hideFooter={true}
            columns={columns}
            rows={verificationStatuses.data}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => approve(artistId)}>Approve</Button>
          <RejectModal
            mainTitle={'Rejecting Changes'}
            title="Are you sure you want to reject this changes?"
            onPressCallback={handleRejectClick}
            id={artistId}
          />
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewChanges;
