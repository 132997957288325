import { ApplicationProvider } from './providers';
import 'draft-js/dist/Draft.css';

import { i18nManager } from './i18n';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes';

import './global.css';
import { ToastContainer } from 'react-toastify';

i18nManager.initialize();
export default function App() {
  return (
    <ApplicationProvider>
      <Routes />
      <ToastContainer />
    </ApplicationProvider>
  );
}
