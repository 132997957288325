import React, { ChangeEvent, useState } from 'react';
import { Avatar, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'formik';

interface IFieldSimpleProps extends Pick<FieldProps, 'field' | 'form'> {
  src?: string;
}

const ImagePicker: React.FC<IFieldSimpleProps> = ({ field, form }) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const { t } = useTranslation();
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result as string);
    };
    form.setFieldValue(field.name, file);
    reader.readAsDataURL(file!);
  };
  return (
    <Box mt={3} mb={2} display="flex" alignItems="center">
      <Avatar style={{ width: 40, height: 40 }} src={imageUrl || field.value} />
      <label htmlFor="upload-image">
        <Button style={{ marginLeft: 16 }} variant="outlined" component="span">
          {t('projects.upload')}
        </Button>
        <input id="upload-image" hidden accept="image/*" type="file" onChange={handleFileUpload} />
      </label>
    </Box>
  );
};

export default ImagePicker;
