import React, { useCallback, useState } from 'react';
import { useFetchProjectsQuery } from '@app/features/Projects/hooks';
import DataTable from '@app/components/DataTable';
import SearchField from '@app/components/SearchField';
import { debounce } from '@mui/material';
import { Stack } from '@mui/system';
import { TListRequest } from '@app/types';
import { useNavigate } from 'react-router';
import { onSort, updateFilter } from '@app/utils';
import { ArtistProjectsColumns } from '@app/features/Artists/components/ArtistProjectsColumns';

const ArtistProjects = ({ artistId }: { artistId: string }) => {
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });
  const navigate = useNavigate();

  const { data, isFetching } = useFetchProjectsQuery({
    artist_id: artistId,
    page: filter.page,
    limit: filter.limit,
    order_dir: filter.order_dir,
    order_by: filter.order_by,
    ...(filter.search ? { search: filter.search || '' } : null),
  });

  const projects = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );

  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <SearchField placeholder="Projects" onChange={e => onSearch(e.target.value)} />
      </Stack>

      <DataTable
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        hasCheckboxSelection
        columns={ArtistProjectsColumns()}
        rows={projects}
        loading={isFetching}
        onCellClick={params => {
          if (params.field !== 'id') {
            navigate(`../../projects/view-project?id=${params.id}`);
          }
        }}
        rowCount={itemCount}
        page={filter.page}
        onSortModelChange={model => onSort(model, setFilter)}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
    </>
  );
};

export default ArtistProjects;
