import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import api from '@app/state/api';
import { useDispatch } from 'react-redux';

export const Columns = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Genre Name',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'status',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 80,
      align: 'center',
      renderCell: ({ id }) => {
        return (
          <IconButton
            onClick={() => {
              dispatch(api.util.resetApiState());
              navigate(`edit-genre?id=${id}`);
            }}>
            <Edit />
          </IconButton>
        );
      },
    },
  ] as GridColDef[];
};
