import React, { useEffect } from 'react';
import { Typography, Box, CircularProgress, Grid, Button, Avatar, Alert } from '@mui/material';
import { useApproveProjectMutation, useFetchProjectQuery } from '@app/features/Projects/hooks';
import { useFetchArtistQuery } from '@app/features/Artists/hooks';
import { useNavigate } from 'react-router';
import Row from '@app/features/Artists/components/Row';
import queryString from 'query-string';
import Contracts from '@app/features/Projects/components/Contracts';
import RejectModal from '@app/components/RejectModal';
import ResolveProject from '@app/features/Projects/components/ResolveProject';
import AcceptModal from '@app/components/AcceptModal';
import {
  audioFileClear,
  TProjectRejectRequest,
  trimmedAudioClear,
} from '@app/features/Projects/state';
import { useDispatch } from 'react-redux';

const ViewProject = () => {
  const id = queryString.parse(location.search).id as string;
  const navigate = useNavigate();

  const { data: project, isFetching } = useFetchProjectQuery(id);
  const [approveProject] = useApproveProjectMutation();
  const { data: artist, isFetching: isArtistFetching } = useFetchArtistQuery(project?.artist_id, {
    skip: Boolean(!project?.artist_id),
  });
  const dispatch = useDispatch();

  const handleApproveClick = (values: TProjectRejectRequest) => {
    approveProject(values);
  };

  useEffect(() => {
    return () => {
      dispatch(trimmedAudioClear(undefined));
      dispatch(audioFileClear(undefined));
    };
  }, []);

  return (
    <Box>
      {project?.status === 'waiting_for_approve' && (
        <Box mb={2} mt={2}>
          <Alert severity="error">This project is waiting for your approval to be posted</Alert>
          <Box mt={1}>
            <AcceptModal id={id} onPressCallback={handleApproveClick} />
            <RejectModal id={id} />
          </Box>
        </Box>
      )}

      <Box mb={3} display="flex" justifyContent="space-between">
        <Typography variant={'h4'}>{project?.name}</Typography>
        <Button
          onClick={() => navigate(`../edit-project?id=${id}`, { replace: true })}
          variant="contained">
          Edit Project
        </Button>
      </Box>
      {project?.status === 'completed' && project?.is_approved_completion == null && (
        <ResolveProject projectId={id} />
      )}

      {isFetching || isArtistFetching ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          <Row
            label={'Project image'}
            children={<Avatar style={{ width: 40, height: 40 }} src={project?.image_url} />}
          />
          <Row
            label={'Project genre'}
            children={<Typography>{project?.primary_genre?.name}</Typography>}
          />
          <Row label={'Project Name'} children={<Typography>{project?.name}</Typography>} />
          <Row label={'Artist'} children={<Typography>{artist?.profile?.stage_name}</Typography>} />
          <Row label={'Description'} children={<Typography>{project?.description}</Typography>} />
          <Box ml={2} mt={2}>
            <Typography variant={'h6'}>Goal and Deadline</Typography>
          </Box>
          <Row
            label={'Project goal'}
            children={<Typography>{project?.goals![0].description || ''}</Typography>}
          />
          <Row
            label={'Goal amount, US$'}
            children={<Typography>{project?.goals![0].amount || ''}</Typography>}
          />
          <Row
            label={'Portion of copyright, %'}
            children={<Typography>{project?.collected_amount}</Typography>}
          />
          <Row
            label={'Campaign duration, d'}
            children={<Typography>{project?.duration_days}</Typography>}
          />
          <Row label={'Backers'} children={<Typography>{project?.backers_count}</Typography>} />
          <Row
            label={'Start Date'}
            children={<Typography>{project?.campaign_start_date}</Typography>}
          />
          <Row
            label={'End Date'}
            children={<Typography>{project?.campaign_end_date}</Typography>}
          />
          <Row
            label={'Project status'}
            children={<Typography>{project?.status.split('_').join(' ')}</Typography>}
          />
          {project?.media && (
            <>
              <Row
                label={'Music'}
                children={<audio controls src={project?.media.full_track_url} />}
              />
              <Row
                label={'Fragment'}
                children={<audio controls src={project?.media.short_track_url} />}
              />
            </>
          )}
        </Grid>
      )}
      <Box mb={2} mt={2}>
        <Typography variant={'h6'}>Contracts</Typography>
      </Box>
      <Contracts id={id} />
    </Box>
  );
};

export default ViewProject;
