import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';

const Row = ({ label, children }: { label: string; children: ReactElement }) => {
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} style={{ color: 'gray' }}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Row;
