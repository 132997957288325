import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import TextInput from '@app/components/TextInput';
import { useTranslation } from 'react-i18next';
import { useFetchGenresQuery } from '@app/features/Genres/hooks';
import { useNavigate } from 'react-router';
import ImagePicker from '@app/components/ImagePicker';
import { TGenre } from '@app/features/Genres/state';
import CheckboxField from '@app/components/CheckBoxField';
import { createNewArtistSchema, updateArtistSchema } from './schemas/createArtist.schema';
import {
  useCreateArtistMutation,
  useFeatureArtistMutation,
  useFetchArtistQuery,
  useUpdateArtistMutation,
  useUploadAvatarMutation,
} from '@app/features/Artists/hooks';
import { TArtistRequest } from '@app/features/Artists/state';
import showError from '@app/components/ErrorAlert';
import { getInitialValue } from '@app/features/Artists/utils/getInitialValue';
import queryString from 'query-string';
import { optionsVerification } from '@app/features/Projects/constants';

const ArtistDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { id } = useParams();

  const id = queryString.parse(location.search).id as string;

  const [createArtist, { isSuccess: isCreateSuccess, isError, error }] = useCreateArtistMutation();
  const [featureArtist] = useFeatureArtistMutation();
  const [updateArtist, { isSuccess: isUpdateSuccess }] = useUpdateArtistMutation();
  const [uploadAvatar] = useUploadAvatarMutation();

  const { data: genres } = useFetchGenresQuery({});
  const { data: artist, isLoading } = useFetchArtistQuery(id, { skip: !id });

  const onHandleSubmit = (values: FormikValues) => {
    const preparedObj: TArtistRequest = {
      stage_name: values.stageName,
      genres: values.genres.map((genre: { id: number }) => genre.id),
      artist_type: values.artistType,
      email: values.email,
      password: values.password,
      ...(id && { id }),
      ...(values.firstName && { first_name: values.firstName }),
      ...(values.lastName && { last_name: values.lastName }),
      ...(values.instagramUrl && { instagram_url: values.instagramUrl }),
      ...(values.spotifyUrl && { spotify_url: values.spotifyUrl }),
      ...(values.appleMusicUrl && { apple_music_url: values.appleMusicUrl }),
      ...(values.tiktokUrl && { tiktok_url: values.tiktokUrl }),
      ...(values.verificationStatus && { verification_status: values.verificationStatus }),
      ...(values.rejectVerificationReason && {
        reject_verification_reason: values.rejectVerificationReason,
      }),
    };
    if (id) {
      updateArtist(preparedObj);
      if (values.image) {
        uploadAvatar({ file: values.image, id });
      }
    } else {
      createArtist(preparedObj);
    }
  };

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess) {
      navigate(-1);
    }
  }, [isUpdateSuccess, isCreateSuccess]);

  useEffect(() => {
    if (isError && error) {
      showError(error);
    }
  }, [isError, error]);

  return (
    <Box>
      <Typography variant={'h4'}>
        {t(id ? 'artists.updateArtist' : 'artists.createArtist')}
      </Typography>
      {!isLoading ? (
        <Formik
          initialValues={getInitialValue(artist!)}
          validationSchema={id ? updateArtistSchema : createNewArtistSchema}
          onSubmit={onHandleSubmit}>
          <Form>
            <Box mt={5} mb={2}>
              <Typography variant={'h6'}>Major info</Typography>
            </Box>
            <Grid xs={6} container direction="column">
              <Grid display={'flex'} alignItems={'center'}>
                {id && (
                  <Field name="image">
                    {(innerProps: FieldProps) => {
                      return <ImagePicker {...innerProps} />;
                    }}
                  </Field>
                )}
              </Grid>
              <Field name="stageName">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('artists.stageName')} {...innerProps} />;
                }}
              </Field>
              <Field name="firstName">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('fans.firstName')} {...innerProps} />;
                }}
              </Field>
              <Field name="lastName">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('fans.lastName')} {...innerProps} />;
                }}
              </Field>
              <Typography mt={4} variant={'h6'}>
                More Details
              </Typography>
              <Field name={'genres'}>
                {(innerProps: FieldProps) => (
                  <Autocomplete
                    onChange={(_, value) => {
                      innerProps.form.setFieldValue('genres', value);
                    }}
                    getOptionLabel={option => option.name}
                    value={innerProps.field.value}
                    options={genres || []}
                    multiple
                    renderInput={params => (
                      <TextInput
                        label={'Genres'}
                        onChangeCustom={(e: string) => {
                          innerProps.form.setFieldValue('genre', {
                            name: e,
                          });
                        }}
                        customError={innerProps.form.errors.genres as unknown as TGenre}
                        {...params}
                        {...innerProps}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Field>
              <Box display="flex" flexDirection="row">
                <Box>
                  <Typography color="gray" mb={1}>
                    Type
                  </Typography>
                  <Field name="artistType">
                    {(innerProps: FieldProps) => {
                      return (
                        <ButtonGroup variant="outlined">
                          <Button
                            onClick={() => innerProps.form.setFieldValue('artistType', 'solo')}>
                            <Typography
                              fontSize={14}
                              color={innerProps.field.value !== 'solo' ? 'gray' : 'inherit'}>
                              Solo
                            </Typography>
                          </Button>
                          <Button
                            onClick={() => innerProps.form.setFieldValue('artistType', 'band')}>
                            <Typography
                              fontSize={14}
                              color={innerProps.field.value !== 'band' ? 'gray' : 'inherit'}>
                              Band
                            </Typography>
                          </Button>
                        </ButtonGroup>
                      );
                    }}
                  </Field>
                </Box>
                {id && (
                  <Box ml={15}>
                    <Typography color="gray">Featured</Typography>
                    <Field name="featured">
                      {(innerProps: FieldProps) => {
                        return (
                          <CheckboxField onChaneCustom={() => featureArtist(id)} {...innerProps} />
                        );
                      }}
                    </Field>
                  </Box>
                )}
              </Box>
              <Typography mt={4} variant={'h6'}>
                Social Media
              </Typography>
              <Field name="tiktokUrl">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('artists.tiktok')} {...innerProps} />;
                }}
              </Field>
              <Field name="instagramUrl">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('artists.instagram')} {...innerProps} />;
                }}
              </Field>
              <Field name="spotifyUrl">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('artists.spotify')} {...innerProps} />;
                }}
              </Field>
              <Field name="appleMusicUrl">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('artists.appleMusic')} {...innerProps} />;
                }}
              </Field>
              {id && (
                <>
                  <Typography mt={4} variant={'h6'}>
                    Verification
                  </Typography>
                  <Field name="verificationStatus">
                    {(innerProps: FieldProps) => (
                      <Autocomplete
                        disableClearable={true}
                        filterOptions={x => x}
                        inputValue={innerProps.field.value}
                        onChange={(_, value) =>
                          innerProps.form.setFieldValue('verificationStatus', value)
                        }
                        options={optionsVerification}
                        renderInput={params => (
                          <TextInput label={t('artists.verStatus')} {...params} {...innerProps} />
                        )}
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="rejectVerificationReason">
                    {(innerProps: FieldProps) => {
                      if (innerProps.form.values.verificationStatus === 'rejected')
                        return <TextInput label={t('artists.reasonRejection')} {...innerProps} />;
                    }}
                  </Field>
                </>
              )}
              <Typography mt={4} variant={'h6'}>
                Authentication
              </Typography>
              <Field name="email">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('fans.email')} {...innerProps} />;
                }}
              </Field>
              {!id && (
                <Field name="password">
                  {(innerProps: FieldProps) => {
                    return <TextInput label={t('fans.password')} {...innerProps} />;
                  }}
                </Field>
              )}

              <Box display="flex" mt={3} justifyContent="flex-end">
                <Button onClick={() => navigate(-1)} style={{ marginRight: 15 }}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  {id ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Box>
  );
};

export default ArtistDetails;
