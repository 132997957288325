import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { useFetchProjectQuery, useUpdateProjectMutation } from '@app/features/Projects/hooks';
import showError from '@app/components/ErrorAlert';
import UploadAudio from '@app/features/Projects/components/UploadAudio';
import {
  audioFileClear,
  TProjectUpdateRequest,
  trimmedAudioClear,
} from '@app/features/Projects/state';
import { useDispatch } from 'react-redux';
import FieldsForm from '@app/features/Projects/components/FieldsForm';
import AudioPlayer from '@app/features/Projects/components/AudioPlayer';
import { getStatusName } from '@app/utils';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { createNewProjectSchema } from '@app/features/Projects/schemas/createNewProject.schema';

const EditProject = () => {
  const id = queryString.parse(location.search).id as string;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: project, isFetching } = useFetchProjectQuery(id);
  const [updateProject, { isLoading, isError, error, isSuccess }] = useUpdateProjectMutation();

  const onHandleSubmit = (values: FormikValues) => {
    updateProject({
      id: project!.id,
      name: values.name,
      primary_genre_id: values.genre.id,
      share_percent: values.sharePercent,
      duration_days: values.durationDays,
      goals: JSON.stringify([{ description: values.projectGoal, amount: +values.goalAmount }]),
      ...(values.status && { status: getStatusName(values.status as string) }),
      ...(values.description && { description: values.description }),
      ...(values.image && typeof values.image != 'string' && { image: values.image }),
    } as TProjectUpdateRequest);
  };

  useEffect(() => {
    return () => {
      dispatch(trimmedAudioClear(undefined));
      dispatch(audioFileClear(undefined));
    };
  }, []);

  useEffect(() => {
    if (error) {
      showError(JSON.stringify(error));
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/admin/projects');
    }
  }, [navigate, isSuccess]);

  return (
    <Box>
      <Typography mb={3} fontSize={30}>
        Edit Project
      </Typography>
      <>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <>
            <Formik
              initialValues={{
                name: project?.name,
                description: project?.description || '',
                genre: {
                  name: project?.primary_genre?.name,
                  id: project?.primary_genre?.id,
                },
                artist: {
                  name: '',
                  id: project?.artist_id,
                },
                image: project?.image_url,
                sharePercent: project?.share_percent,
                durationDays: project?.duration_days,
                goalAmount: project?.goals![0].amount,
                projectGoal: project?.goals![0].description,
                status: project?.status.split('_').join(' '),
              }}
              validationSchema={createNewProjectSchema}
              onSubmit={onHandleSubmit}>
              <Form>
                <FieldsForm isEditProject />
                <Grid container xs={5} display="flex" justifyContent="flex-end">
                  <Box>
                    <Button
                      style={{ width: 200, marginBottom: 30, marginLeft: 'auto' }}
                      variant="contained"
                      type="submit">
                      {isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        'Update Project'
                      )}
                    </Button>
                  </Box>
                </Grid>
                {project?.media ? (
                  <AudioPlayer isEditProject projectId={project.id!} />
                ) : (
                  <UploadAudio projectId={project!.id!} />
                )}
              </Form>
            </Formik>
          </>
        )}
      </>
    </Box>
  );
};

export default EditProject;
