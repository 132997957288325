import React, { useEffect, useState } from 'react';
import { useFetchTermsAdminQuery, useUpdateTermsMutation } from './hooks';
import parse from 'html-react-parser';
import { Box, Button, CircularProgress } from '@mui/material';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ViewTermsAndConditions = () => {
  const [editMode, setEditMode] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const { data, isLoading } = useFetchTermsAdminQuery({});
  const [updateTerms, { isLoading: isUpdateLoading, isSuccess }] = useUpdateTermsMutation();
  useEffect(() => {
    if (data?.terms) {
      const blocksFromHTML = convertFromHTML(data.terms);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [data]);
  useEffect(() => {
    if (isSuccess) {
      setEditMode(false);
    }
  }, [isSuccess]);
  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
  };
  const onContentSave = () => {
    const contentState = editorState.getCurrentContent();
    const result = draftToHtml(convertToRaw(contentState));
    updateTerms({
      terms: result,
    });
  };

  return (
    <Box>
      <Box width={'100%'} display="flex" justifyContent="flex-end" mb={3}>
        <Button onClick={() => setEditMode(true)} variant="contained">
          Edit Terms
        </Button>
      </Box>
      {editMode ? (
        <>
          <Editor
            toolbar={toolbarOptions}
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
          <Box mt={4} width="100%" display="flex" justifyContent="flex-end">
            <Button style={{ width: 80 }} onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <Button style={{ width: 80 }} onClick={() => onContentSave()} variant="contained">
              {isUpdateLoading ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </Button>
          </Box>
        </>
      ) : (
        <>{isLoading ? <CircularProgress /> : parse(data?.terms as string)}</>
      )}
    </Box>
  );
};

export default ViewTermsAndConditions;
