import { useMemo } from 'react';

import useAppSelector from './useAppSelector';
import { selectToken } from '@app/state/selectors';

function useAuth() {
  const isAuthenticated = useAppSelector(selectToken);

  return useMemo(() => ({ isAuthenticated }), [isAuthenticated]);
}

export default useAuth;
