import React, { useState } from 'react';
import DataTable from '@app/components/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { TListRequest } from '@app/types';
import { updateFilter } from '@app/utils';
import { useFetchFanProjectsQuery } from '@app/features/Fans/hooks';

const Contracts = ({ id }: { id: string }) => {
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useFetchFanProjectsQuery({
    id,
    page: filter.page,
    limit: filter.limit,
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Project',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'fan_share_percent',
      headerName: 'Royalty share, %',
      headerAlign: 'left',
      width: 170,
      align: 'left',
      sortable: false,
    },
    {
      field: 'contracts',
      headerName: 'Contract',
      valueGetter: params =>
        params.row.contracts.length
          ? params.row.contracts[0].fan_agreement?.split('_').join(' ')
          : '',
      headerAlign: 'left',
      width: 200,
      align: 'left',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Actions',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: params => {
        return (
          <>
            {params.row?.contracts?.length ? (
              <IconButton
                onClick={() =>
                  window.open(params.row.contracts[0].document_url as string, '_blank')
                }>
                <Download />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
  ];

  const contracts = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  return (
    <DataTable
      onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
      pageSize={pageSize}
      rowCount={itemCount}
      loading={isFetching}
      columns={columns}
      page={filter.page}
      rows={contracts}
      onPageChange={page => updateFilter('page', page, setFilter)}
    />
  );
};
export default Contracts;
