import React from 'react';
import { Typography, Box, CircularProgress, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useFetchFanQuery, useUnblockFanMutation } from '@app/features/Fans/hooks';
import Row from '@app/features/Artists/components/Row';
import queryString from 'query-string';
import FanContracts from '@app/features/Fans/components/FanContracts';
import ArtistAndFanBlock from '@app/components/ArtistAndFanBlock';
import ArtistAndFanDelete from '@app/components/ArtistAndFanDelete';

const ViewFan = () => {
  const id = queryString.parse(location.search).id as string;
  const [unblock] = useUnblockFanMutation();

  const navigate = useNavigate();

  const { data: fan, isFetching } = useFetchFanQuery(id);
  return (
    <Box>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box mt={2} mb={2}>
              <Typography variant={'h6'}>{fan?.first_name || fan?.email}</Typography>
            </Box>
            <Box>
              {fan?.is_deleted ? (
                <Typography>This fan deleted</Typography>
              ) : (
                <>
                  <ArtistAndFanDelete isArtist={false} id={id} />
                  {fan?.is_blocked ? (
                    <Button
                      style={{ marginRight: 20 }}
                      variant="contained"
                      onClick={() => unblock(id)}>
                      Unblock
                    </Button>
                  ) : (
                    <ArtistAndFanBlock isArtist={false} id={id} />
                  )}
                  <Button
                    style={{ width: 143 }}
                    variant="contained"
                    onClick={() => navigate(`../edit-fan?id=${id}`, { replace: true })}>
                    Edit Details
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Row label={'First Name'} children={<Typography>{fan?.first_name}</Typography>} />
            <Row label={'Last Name'} children={<Typography>{fan?.last_name}</Typography>} />
            <Row label={'Email'} children={<Typography>{fan?.email}</Typography>} />
          </Grid>
          <>
            <Box ml={2} mt={5} mb={2}>
              <Typography variant={'h6'}>Stripe verification status</Typography>
            </Box>
            <Row
              label={'Is onboarding passed'}
              children={
                <Typography>
                  {String(fan?.stripe_payment_profile?.is_onboarding_passed || 'false')}
                </Typography>
              }
            />
            <Row
              label={'Is document verified'}
              children={
                <Typography>
                  {String(fan?.stripe_payment_profile?.is_document_verified || 'false')}
                </Typography>
              }
            />
          </>
          <Box mt={3}>
            <FanContracts id={id} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ViewFan;
