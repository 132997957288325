import { api } from '../state';

export const {
  useFetchProjectsQuery,
  useCreateProjectMutation,
  useTrimAudioMutation,
  useVerifyAudioMutation,
  useAttachAudioMutation,
  useFetchProjectQuery,
  useUpdateProjectMutation,
  useTrimExistingAudioMutation,
  useDeleteProjectMutation,
  useFetchContributorsQuery,
  useRejectProjectMutation,
  useApproveProjectMutation,
  useApproveCompletionMutation,
  useRejectProjectCompletionMutation,
} = api;
