import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';

export const Columns = () => {
  const getNameOrEmailValue = (params: GridValueGetterParams) => {
    if (params.row && params.row.first_name && params.row.last_name) {
      return `${params.row.first_name as string} ${params.row.last_name as string}`;
    } else {
      return params.row?.email;
    }
  };

  const getVerifValue = (params: GridValueGetterParams) => {
    if (
      params.row.stripe_payment_profile?.is_document_verified &&
      params.row.stripe_payment_profile?.is_onboarding_passed
    ) {
      return 'verified';
    } else {
      return 'none';
    }
  };
  const navigate = useNavigate();
  return [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name/Email',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      valueGetter: params => getNameOrEmailValue(params),
      sortable: false,
    },
    {
      field: 'verification_status',
      headerName: 'Identity Verification Status',
      flex: 1,
      valueGetter: params => getVerifValue(params),
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 80,
      align: 'center',
      renderCell: ({ id }) => {
        return (
          <>
            <IconButton
              onClick={() => {
                navigate(`edit-fan?id=${id}`);
              }}>
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ] as GridColDef[];
};
