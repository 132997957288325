import { api } from '../state';

export const {
  useFetchArtistsQuery,
  useFetchArtistQuery,
  useCreateArtistMutation,
  useUpdateArtistMutation,
  useFeatureArtistMutation,
  useUploadAvatarMutation,
  useApproveChangesMutation,
  useRejectChangesMutation,
  useFetchChangesHistoryQuery,
  useBlockArtistMutation,
  useUnblockArtistMutation,
  useDeleteArtistMutation,
} = api;
