import * as Yup from 'yup';

// const specialCharactersRegex = /^[!#$%&'*+\-/=?^_`{|]\]*$/;
export const passwordSchema = Yup.string()
  .required('Password cannot be empty')
  .min(8, 'Password is too short. Please enter a minimum of 8 characters')
  .matches(/[0-9]+/g, 'Password must contain numbers')
  .matches(/[A-Z]+/g, 'Password must contain uppercase letters')
  .matches(/[a-z]+/g, 'Password must contain small letters')
  .matches(/^\S+$/, 'Password must not contain spaces');

export const createFanSchema = Yup.object().shape({
  name: Yup.string().optional().nullable().max(50, 'The field must contain up to 50 symbols'),
  lastName: Yup.string().optional().nullable().max(50, 'The field must contain up to 50 symbols'),
  email: Yup.string().required('Email is required').email('Please enter a valid email address'),
  password: passwordSchema,
});
