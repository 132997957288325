import { Routes, Route } from 'react-router-dom';

import ViewTermsAndConditions from './ViewTermsAndConditions';

export default function () {
  return (
    <Routes>
      <Route index element={<ViewTermsAndConditions />} />
    </Routes>
  );
}
