import { baseApi } from '@app/state';
import { prepareFormData } from '@app/utils';
import { projectByIdAdd, trimmedAudioAdded } from '@app/features/Projects';
import { TArtist } from '@app/features/Artists/state';
import { TListRequest } from '@app/types';
export type TGoal = {
  id: number;
  description: string;
  amount: number;
};

export type TGenre = {
  id: number;
  name: string;
};

export type TMedia = {
  id: number;
  short_track_url: string;
  full_track_url: string;
  project_id: number;
  created_at: string;
  updated_at: string;
  from: string;
  duration: string;
  artist_id: number;
};

export type TProject = {
  id?: number;
  name: string;
  description?: string;
  likes: number;
  image_url?: string;
  artist_id: number;
  artist?: TArtist;
  status: string;
  collected_amount: number;
  share_percent?: number;
  campaign_start_date?: string;
  campaign_end_date?: string;
  backers_count: number;
  duration_days?: number;
  is_approved_completion?: boolean;
  media?: TMedia;
  primary_genre?: TGenre;
  goals?: TGoal[];
};
export type TAudio = {
  full_track_url: string;
  short_track_url: string;
  project_id: number;
  from: number;
  duration: number;
  artist_id: number;
  updated_at: string;
  created_at: string;
  id: number;
};

export type TListResponse = {
  current_page: number;
  data: TProject[];
  first_page_url: string;
  from: number;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: any;
  to: number;
  total: number;
};

export type TProjectCreateRequest = {
  name: string;
  artist_id: number;
  description?: string;
  primary_genre_id: number;
  share_percent: number;
  duration_days: number;
  goals: string;
  image?: File;
};
export type TProjectUpdateRequest = {
  id: string;
  name: string;
  description?: string;
  primary_genre_id: number;
  share_percent: number;
  duration_days: number;
  goals: string;
  image?: File;
};

export type TTrimAudioRequest = {
  file?: File;
  from: number;
  duration?: number;
  project_id: string | number;
};
export type TAudioRecogniseRequest = {
  from: number;
  project_id: string | number;
};
export type TAttachProject = {
  project_id: string | number;
  media_id: number;
};

export type TProjectRejectRequest = {
  id: string;
  comment?: string;
};

export const api = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Projects'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      fetchProjects: builder.query<TListResponse, TListRequest>({
        query: params => {
          return {
            url: 'admin/projects',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Projects'],
      }),
      fetchProject: builder.query<TProject, string>({
        query: id => {
          return {
            url: `admin/projects/${id}`,
            method: 'GET',
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //fetchArtist(data.artist_id);
            dispatch(projectByIdAdd(data));
            // eslint-disable-next-line no-empty
          } catch {}
        },
        providesTags: ['Projects'],
      }),
      createProject: builder.mutation<TProject, TProjectCreateRequest>({
        query: (body: any) => {
          return {
            url: 'admin/projects',
            method: 'POST',
            body: prepareFormData(body),
          };
        },
      }),
      updateProject: builder.mutation<TProject, TProjectUpdateRequest>({
        query: project => {
          return {
            url: `admin/projects/${project.id}`,
            method: 'POST',
            body: prepareFormData(project),
          };
        },
        invalidatesTags: ['Projects'],
      }),
      trimAudio: builder.mutation<TAudio, TTrimAudioRequest>({
        query: (body: any) => {
          return {
            url: 'admin/audio/trim',
            method: 'POST',
            body: prepareFormData(body),
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            dispatch(trimmedAudioAdded(data));
            // eslint-disable-next-line no-empty
          } catch {}
        },
      }),
      trimExistingAudio: builder.mutation<TAudio, TTrimAudioRequest>({
        query: (body: any) => {
          return {
            url: 'admin/audio/trim-existing',
            method: 'POST',
            body: prepareFormData(body),
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            dispatch(trimmedAudioAdded(data));
            // eslint-disable-next-line no-empty
          } catch {}
        },
      }),
      verifyAudio: builder.mutation<any, TAudioRecogniseRequest>({
        query: (body: any) => {
          return {
            url: 'admin/audio/recognize',
            method: 'POST',
            body: prepareFormData(body),
          };
        },
      }),
      attachAudio: builder.mutation<{ project_id: number; media_id: number }, TAttachProject>({
        query: ({ project_id, media_id }) => {
          return {
            url: `admin/projects/${project_id}/media`,
            method: 'POST',
            body: { media_id },
          };
        },
      }),
      deleteProject: builder.mutation<any, string>({
        query: id => {
          return {
            url: `admin/projects/${id}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['Projects'],
      }),
      fetchContributors: builder.query<any, TListRequest>({
        query: params => {
          return {
            url: `admin/projects/${params.id!}/contributors`,
            method: 'GET',
            params,
          };
        },
      }),
      rejectProject: builder.mutation<any, TProjectRejectRequest>({
        query: body => {
          return {
            url: `/admin/projects/${body.id}/reject`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Projects'],
      }),
      approveProject: builder.mutation<any, TProjectRejectRequest>({
        query: body => {
          return {
            url: `/admin/projects/${body.id}/approve`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Projects'],
      }),
      rejectProjectCompletion: builder.mutation<any, string>({
        query: id => {
          return {
            url: `/admin/projects/${id}/reject-completion`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Projects'],
      }),
      approveCompletion: builder.mutation<any, string>({
        query: id => {
          return {
            url: `/admin/projects/${id}/approve-completion`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Projects'],
      }),
    }),
  });
