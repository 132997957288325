import { baseApi } from '@app/state';

export const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchTerms: builder.query<{ terms: string }, any>({
      query: () => {
        return {
          url: 'config/terms',
          method: 'GET',
        };
      },
    }),
  }),
});
