import React, { useEffect } from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, Formik } from 'formik';
import { TLogIn } from '@app/types';
import TextInput from '@app/components/TextInput';
import { LoginSchema } from '@app/features/Authentication/schemas/login.schema';
import { useLoginMutation } from '@app/features/Authentication/hooks';
import { useNavigate } from 'react-router';
import showError from '@app/components/ErrorAlert';

const LogIn = () => {
  const { t } = useTranslation();
  const [login, { isSuccess, error }] = useLoginMutation();
  const navigate = useNavigate();

  const onSubmit = (values: TLogIn) => {
    login(values);
  };

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/admin');
    }
  }, [navigate, isSuccess]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" maxWidth={400}>
        <Paper
          elevation={1}
          sx={{
            padding: 2,
          }}>
          <>
            <Typography textAlign="center" component="h1" variant="h5">
              {t('login.authentication')}
            </Typography>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <>
                  <Field name="email">
                    {(innerProps: FieldProps) => {
                      return <TextInput label={t('login.email')} {...innerProps} />;
                    }}
                  </Field>
                  <Field name="password">
                    {(innerProps: FieldProps) => {
                      return (
                        <TextInput type="password" label={t('login.password')} {...innerProps} />
                      );
                    }}
                  </Field>
                  <Button
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}>
                    {t('login.logIn')}
                  </Button>
                </>
              )}
            </Formik>
          </>
        </Paper>
      </Box>
    </Box>
  );
};

export default LogIn;
