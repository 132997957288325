import { Routes, Route } from 'react-router-dom';
import Artists from './Artists';
import ArtistDetails from '@app/features/Artists/ArtistDetails';
import ArtistView from '@app/features/Artists/ArtistView';
import ViewChangeHistory from '@app/features/Artists/ViewChangeHistory';

export default function () {
  return (
    <Routes>
      <Route index element={<Artists />} />
      <Route path="create-artist" element={<ArtistDetails />} />
      <Route path="update-artist" element={<ArtistDetails />} />
      <Route path=":id" element={<ArtistView />} />
      <Route path=":id/view-changes-history" element={<ViewChangeHistory />} />
    </Routes>
  );
}
