import { Routes, Route } from 'react-router-dom';
import Genres from './Genres';
import GenreDetails from './GenreDetails';

export default function () {
  return (
    <Routes>
      <Route index element={<Genres />} />
      <Route path="create-genre" element={<GenreDetails />} />
      <Route path="edit-genre" element={<GenreDetails />} />
    </Routes>
  );
}
