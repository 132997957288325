import React, { useCallback, useState } from 'react';
import DataTable from '@app/components/DataTable';
import { Box, debounce, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { TListRequest } from '@app/types';
import { updateFilter } from '@app/utils';
import { useNavigate } from 'react-router';
import { GridColDef } from '@mui/x-data-grid';
import { useFetchProjectEarningsQuery } from '@app/features/Payments/hooks';
import DistributionEarning from '@app/features/Payments/components/DistributionEarning';
import SearchField from '@app/components/SearchField';

const ProjectEarningsTab = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useFetchProjectEarningsQuery({
    page: filter.page,
    limit: filter.limit,
    ...(filter.search ? { search: filter.search || '' } : null),
  });

  const projectEarnings = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const columns: GridColDef[] = [
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: params => params?.value || '',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'admin',
      headerName: 'Admin Email',
      valueGetter: params => params?.value.email || '',
      headerAlign: 'left',
      align: 'left',
      width: 200,
      sortable: false,
    },
    {
      field: 'project',
      headerName: 'Project',
      width: 200,
      valueGetter: params => params?.value.name || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: params => params?.value || '',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
  ];
  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );
  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography variant="h4">Projects Earnings</Typography>
        </Box>
        <SearchField placeholder="Projects Earnings" onChange={e => onSearch(e.target.value)} />
      </Stack>
      <DataTable
        columns={columns}
        rows={projectEarnings}
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        onCellClick={params => {
          if (params.field !== 'id') {
            navigate(`view-project-earnings?id=${params.id}`);
          }
        }}
        loading={isFetching}
        rowCount={itemCount}
        page={filter.page}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
      <DistributionEarning open={open} setOpen={setOpen} />
    </>
  );
};

export default ProjectEarningsTab;
