import { baseApi } from '@app/state';
import { TArtist, TStipePaymentObject } from '@app/features/Artists/state';
import { TGenre, TGoal } from '@app/features/Projects';

export type TListRequest = {
  id?: string;
  page?: number;
  limit?: number;
  per_page?: number;
};
export interface TFans {
  id: number;
  email: string;
  email_verified_at: any;
  created_at: string;
  updated_at: string;
  last_activity_at: any;
  language: string;
  is_blocked?: boolean;
  followers_count: any;
  avatar_url: any;
  first_name: any;
  last_name: any;
  role: string;
}

export type TListResponse = {
  current_page: number;
  data: TFans[];
  first_page_url: string;
  from: number;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: any;
  to: number;
  total: number;
};

export type TCreateFanRequest = {
  id?: string;
  email: string;
  password: string;
  first_name?: string;
  last_name?: string;
};

export type TFanResponse = {
  id: number;
  email: string;
  email_verified_at: any;
  created_at: string;
  updated_at: string;
  last_activity_at: any;
  language: string;
  followers_count: number;
  avatar_url: any;
  first_name: string;
  is_blocked?: boolean;
  is_deleted?: boolean;
  last_name: string;
  projects_count: number;
  role: string;
  stripe_payment_profile?: TStipePaymentObject;
};

export type TFanProjectsResponse = {
  data: {
    id: number;
    name: string;
    description: string;
    likes: number;
    primary_genre_id: number;
    media_id: number;
    image_url: string | null;
    campaign_end_date: number;
    artist_id: number;
    campaign_start_date: number;
    collected_amount: number;
    fan_total_amount: number;
    fan_total_amount_without_taxes: number;
    fan_share_percent: number;
    status: string;
    goals: TGoal[];
    primary_genre: TGenre;
    artist: TArtist;
    contracts: [];
  }[];
  per_page: number;
  total: number;
};

export const api = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Fans'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      fetchFans: builder.query<TListResponse, TListRequest>({
        query: params => {
          return {
            url: 'admin/fans',
            method: 'GET',
            params,
          };
        },
        providesTags: ['Fans'],
      }),
      fetchFanInfo: builder.query<TFanResponse, string>({
        query: id => {
          return {
            url: `admin/fans/${id}`,
            method: 'GET',
          };
        },
        providesTags: ['Fans'],
      }),
      fetchFan: builder.query<TFanResponse, string>({
        query: id => {
          return {
            url: `admin/fans/${id}`,
            method: 'GET',
          };
        },
        providesTags: ['Fans'],
      }),
      createFan: builder.mutation<TFanResponse, TCreateFanRequest>({
        query: body => {
          return {
            url: 'admin/fans',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Fans'],
      }),
      updateFan: builder.mutation<TFanResponse, TCreateFanRequest>({
        query: body => {
          return {
            url: `admin/fans/${body.id!}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: ['Fans'],
      }),
      fetchFanProjects: builder.query<TFanProjectsResponse, TListRequest>({
        query: params => {
          return {
            url: `admin/fans/${params.id}/projects`,
            method: 'GET',
            params,
          };
        },
      }),
      blockFan: builder.mutation<any, { id: string; reason: string }>({
        query: body => {
          return {
            url: `admin/fans/${body.id}/block`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Fans'],
      }),
      unblockFan: builder.mutation<any, string>({
        query: id => {
          return {
            url: `admin/fans/${id}/unblock`,
            method: 'POST',
          };
        },
        invalidatesTags: ['Fans'],
      }),
      deleteFan: builder.mutation<any, { id: string; reason: string }>({
        query: body => {
          return {
            url: `admin/fans/${body.id}`,
            method: 'DELETE',
            body,
          };
        },
        invalidatesTags: ['Fans'],
      }),
    }),
  });
