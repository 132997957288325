import { Routes, Route, Navigate } from 'react-router-dom';
import { Artists, Authentication, Config, Fans, Genres, Payments, Projects } from '@app/features';
import { ProtectedRoute } from '@app/utils/components';
import Layout from '@app/components/Layout';
import TermsAndConditions from '@app/features/TermsAndConditions/TermsAndConditions';
export default function () {
  return (
    <Routes>
      <Route path="/*" element={<Authentication />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="admin" element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route index element={<Navigate to="projects" replace />} />;
          <Route path="projects/*" element={<Projects />} />
          <Route path="artists/*" element={<Artists />} />
          <Route path="fans/*" element={<Fans />} />
          <Route path="genres/*" element={<Genres />} />
          <Route path="payments/*" element={<Payments />} />
          <Route path="config/*" element={<Config />} />
        </Route>
      </Route>
    </Routes>
  );
}
