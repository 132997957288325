import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { PlayArrow, Stop } from '@mui/icons-material';

const audio = new Audio();

const AudioButton = ({ audioUrl }: { audioUrl: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.src = audioUrl;
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  return <IconButton onClick={toggleAudio}>{isPlaying ? <Stop /> : <PlayArrow />}</IconButton>;
};

export default AudioButton;
