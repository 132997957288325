import React, { useCallback, useState } from 'react';
import { useFetchProjectsQuery } from '@app/features/Projects/hooks';
import DataTable from '@app/components/DataTable';
import SearchField from '@app/components/SearchField';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  debounce,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Columns } from '@app/features/Projects/components/Columns';
import { useNavigate } from 'react-router';
import { onSort, updateFilter } from '@app/utils';
import { TListRequest } from '@app/types';
import CheckboxFilter from '@app/components/CheckboxFilter';
const Projects = () => {
  const [filter, setFilter] = useState<TListRequest>({
    page: 1,
    limit: 10,
  });
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data, isFetching } = useFetchProjectsQuery({
    page: filter.page,
    limit: filter.limit,
    order_dir: filter.order_dir,
    order_by: filter.order_by,
    ...(filter.search ? { search: filter.search || '' } : null),
    ...(filter.expired ? { expired: '1' || '' } : null),
    ...(filter.status ? { status: filter.status || '' } : null),
  });

  const projects = data?.data || [];

  const itemCount = data?.total;

  const pageSize = data?.per_page;

  const onSearch = useCallback(
    debounce(
      (newValue: string) => updateFilter('search', newValue.toLocaleLowerCase(), setFilter),
      1000,
    ),
    [],
  );

  return (
    <>
      <Stack spacing={'30px'} mb="20px" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={'row'}>
          <Typography variant="h4">Projects</Typography>
          <Link style={{ color: '#fff', textDecoration: 'none' }} to="create-new-project">
            <Button variant="contained" color="primary">
              {t('projects.newProject')}
            </Button>
          </Link>
        </Box>
        <SearchField placeholder="Projects" onChange={e => onSearch(e.target.value)} />
      </Stack>
      <Box>
        <Box sx={{ width: 280 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={event => updateFilter('status', event.target.value as string, setFilter)}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="waiting_for_approve">Waiting for approve</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <CheckboxFilter field="expired" filter={filter} setFilter={setFilter} label={'Expired'} />
      </Box>

      <DataTable
        onPageSizeChange={newSize => updateFilter('limit', newSize, setFilter)}
        pageSize={pageSize}
        hasCheckboxSelection
        columns={Columns()}
        rows={projects}
        loading={isFetching}
        onCellClick={params => {
          if (params.field !== 'id') {
            navigate(`view-project?id=${params.id}`);
          }
        }}
        rowCount={itemCount}
        page={filter.page}
        onSortModelChange={model => onSort(model, setFilter)}
        onPageChange={page => updateFilter('page', page, setFilter)}
      />
    </>
  );
};

export default Projects;
