import { SerializedError } from '@reduxjs/toolkit';

import { CustomFetchBaseQueryError } from '@app/types';
import { toast } from 'react-toastify';

type Props = CustomFetchBaseQueryError | SerializedError | string;

function showError(error: Props) {
  let message;

  console.log('error', error);

  if (typeof error === 'string') {
    message = error;
  } else if ('data' in error) {
    message = error.data.message;
  } else {
    if ('message' in error) {
      message = error.message;
    }
  }

  return toast.error(message as string, {
    position: 'top-right',
    closeOnClick: true,
    theme: 'colored',
  });
}

export default showError;
