import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { Download, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import api from '@app/state/api';
import { useDispatch } from 'react-redux';
import AudioButton from '@app/features/Projects/components/AudioButton';
import { downloadResource } from '@app/utils';
import DeleteModal from '@app/features/Projects/components/DeleteModal';

export const ArtistProjectsColumns = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return [
    {
      field: 'name',
      headerName: 'Project Name',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'primary_genre',
      headerName: 'Genre',
      headerAlign: 'left',
      width: 80,
      align: 'left',
      valueGetter: params => params.value.name || '',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      width: 80,
      align: 'left',
      sortable: false,
    },
    {
      field: 'goals',
      headerName: 'Goal',
      headerAlign: 'left',
      width: 80,
      valueGetter: params => params.value[0].amount,
      align: 'left',
      sortable: true,
    },
    {
      field: 'collected_amount',
      headerName: 'Collected, $',
      headerAlign: 'left',
      width: 140,
      align: 'left',
      sortable: true,
    },
    {
      field: 'campaign_start_date',
      headerName: 'Start Date',
      headerAlign: 'left',
      width: 150,
      align: 'left',
      sortable: false,
    },
    {
      field: 'campaign_end_date',
      headerName: 'End date/duration',
      headerAlign: 'left',
      width: 150,
      align: 'left',
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Actions',
      sortable: false,
      width: 170,
      align: 'left',
      renderCell: params => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(api.util.resetApiState());
                navigate(`../../projects/edit-project?id=${params.id}`);
              }}>
              <Edit />
            </IconButton>
            {params.row.media?.full_track_url && (
              <AudioButton audioUrl={params.row.media?.full_track_url} />
            )}
            <IconButton
              onClick={() => {
                downloadResource(params.row.media?.full_track_url as string, 'audio');
              }}>
              <Download />
            </IconButton>
            <DeleteModal projectId={params.id as string} />
          </>
        );
      },
    },
  ] as GridColDef[];
};
