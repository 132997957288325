import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import store from '@app/state/store';
import { api } from './api';
import { logout, RefreshResponse, tokensReceived } from '@app/state';

export type TAuthState = {
  token: string | null;
  refreshToken: string | null;
};

export type LoginPayload = {
  token: string;
  refresh_token: string;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
  } as TAuthState,
  reducers: {},
  extraReducers: builder => {
    return builder
      .addCase(logout, state => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        state.token = null;
        state.refreshToken = null;
      })
      .addCase(
        tokensReceived,
        (state, { payload: { token, refresh_token } }: PayloadAction<RefreshResponse>) => {
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refresh_token);
          state.token = token;
          state.refreshToken = refresh_token;
        },
      )
      .addMatcher(
        api.endpoints.login.matchFulfilled,
        (state, { payload: { token, refresh_token } }: PayloadAction<LoginPayload>) => {
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refresh_token);
          state.token = token;
          state.refreshToken = refresh_token;
        },
      );
  },
});

store.injectReducer(slice.name, slice.reducer);

export default slice;
