import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import TextInput from '@app/components/TextInput';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  useCreateFanMutation,
  useFetchFanQuery,
  useUpdateFanMutation,
} from '@app/features/Fans/hooks';
import { TCreateFanRequest } from '@app/features/Fans/state';
import { createFanSchema } from './schemas/createFan.schema';
import { updateFanSchema } from './schemas/updateFan.schema';
import queryString from 'query-string';
import showError from '@app/components/ErrorAlert';

const FanDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const id = queryString.parse(location.search).id as string;

  const [createFan, { isSuccess: isCreateSuccess, isError, error }] = useCreateFanMutation();
  const [updateFan, { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError }] =
    useUpdateFanMutation();
  const { data: fan, isLoading } = useFetchFanQuery(id, { skip: !id });

  const onHandleSubmit = (values: FormikValues) => {
    if (id) {
      updateFan({
        id,
        email: values.email,
        ...(values.name && { first_name: values.name }),
        ...(values.lastName && { last_name: values.lastName }),
      } as TCreateFanRequest);
    } else {
      createFan({
        email: values.email,
        password: values.password,
        ...(values.name && { first_name: values.name }),
        ...(values.lastName && { last_name: values.lastName }),
      } as TCreateFanRequest);
    }
  };

  useEffect(() => {
    if (isUpdateSuccess || isCreateSuccess) {
      navigate(-1);
    }
  }, [isUpdateSuccess, isCreateSuccess]);

  useEffect(() => {
    if (isError && error) {
      showError(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isUpdateError && updateError) {
      showError(updateError);
    }
  }, [isUpdateError, updateError]);

  return (
    <Box>
      {!isLoading ? (
        <Formik
          initialValues={{
            name: fan?.first_name,
            lastName: fan?.last_name,
            email: fan?.email,
            password: '',
          }}
          validationSchema={id ? updateFanSchema : createFanSchema}
          onSubmit={onHandleSubmit}>
          <Form>
            <Typography variant={'h4'}>
              {t(id ? fan?.first_name || 'Edit Fan' : 'fans.createFan')}
            </Typography>
            <Grid mt={2} xs={5} container direction="column">
              <Field name="name">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('fans.firstName')} {...innerProps} />;
                }}
              </Field>
              <Field name="lastName">
                {(innerProps: FieldProps) => {
                  return <TextInput label={t('fans.lastName')} {...innerProps} />;
                }}
              </Field>
              <Field name="email">
                {(innerProps: FieldProps) => {
                  return (
                    <TextInput disabled={Boolean(id)} label={t('fans.email')} {...innerProps} />
                  );
                }}
              </Field>
              {!id && (
                <Field name="password">
                  {(innerProps: FieldProps) => {
                    return <TextInput label={t('fans.password')} {...innerProps} />;
                  }}
                </Field>
              )}
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => navigate(-1)} style={{ marginTop: 10 }}>
                  Cancel
                </Button>
                <Button type="submit" style={{ marginLeft: 10, marginTop: 10 }} variant="contained">
                  {id ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Box>
  );
};

export default FanDetails;
