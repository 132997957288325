import { GridSortModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { TListRequest } from '@app/types';

export const formatMimeType = (mimeType: string) => {
  if (mimeType === 'audio/mpeg') {
    return 'MP3';
  } else if (mimeType === 'audio/mp4') {
    return 'MP4';
  } else {
    return 'WAV';
  }
};
export const prepareFormData = (body: any) => {
  const bodyFormData = new FormData();
  for (const key in body) {
    bodyFormData.append(key, body[key] as string | Blob);
  }
  return bodyFormData;
};

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve as () => void, ms));
};

export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
export const getStatusName = (status: string) => {
  return status === 'waiting for approve' ? 'waiting_for_approve' : status;
};

function forceDownload(blob: string, filename: string) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadResource(url: string, filename: string) {
  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: 'cors',
  })
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
}
export const onSort = (
  model: GridSortModel,
  setFilterCallBack: Dispatch<SetStateAction<TListRequest>>,
) => {
  setFilterCallBack(prev => ({
    ...prev,
    order_by: model[0]?.field,
    order_dir: model[0].sort!,
  }));
};
export const updateFilter = (
  k: keyof TListRequest,
  v: string | object | number | boolean,
  setFilterCallBack: Dispatch<SetStateAction<TListRequest>>,
) => setFilterCallBack(prev => ({ ...prev, [k]: v }));
