import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
interface IFieldSimpleProps extends Pick<TextFieldProps & FieldProps, 'field' | 'form'> {
  label?: string;
  multiline?: boolean;
  rows?: number;
  onChangeCustom?: any;
  disable?: boolean;
  type?: 'text' | 'number' | 'password';
  width?: number;
  maxLength?: number;
  password?: boolean;
  trim?: boolean;
  showError?: boolean;
  nestedFields?: boolean;
  disabled?: boolean;
  customError?: any;
  value?: string;
}

const TextInput: React.FC<IFieldSimpleProps> = ({
  label,
  field,
  form,
  onChangeCustom,
  customError,
  disabled = false,
  value,
  ...props
}) => {
  const { t } = useTranslation();
  const onTextChange = (e: string) => {
    if (onChangeCustom) onChangeCustom(e);
    else {
      field.onChange(field.name)(e);
    }
  };
  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      value={value || field.value}
      label={label}
      disabled={disabled}
      onBlur={() => form.setFieldTouched(field.name)}
      name={field.name}
      onChange={e => onTextChange(e.target.value)}
      error={form.touched[field.name] && Boolean(customError || form.errors[field.name])}
      helperText={form.touched[field.name] && t(customError || form.errors[field.name])}
      {...props}
    />
  );
};
export default TextInput;
