import React, { useEffect } from 'react';
import UploadAudio from '@app/features/Projects/components/UploadAudio';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { audioFileClear, trimmedAudioClear } from '@app/features/Projects/state';
import { useDispatch } from 'react-redux';

const AddMusic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const parsed = queryString.parse(location.search);
  useEffect(() => {
    return () => {
      dispatch(trimmedAudioClear(undefined));
      dispatch(audioFileClear(undefined));
    };
  }, []);
  return (
    <Box>
      <Typography variant={'h4'}>{t('projects.newProject')}</Typography>
      <Box mt={2}>
        <Typography>2/2 Upload music</Typography>
      </Box>
      <UploadAudio projectId={parsed.id as string} />
    </Box>
  );
};

export default AddMusic;
