import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { createNewProjectSchema } from '@app/features/Projects/schemas/createNewProject.schema';
import { useCreateProjectMutation } from '@app/features/Projects/hooks';
import showError from '@app/components/ErrorAlert';
import { TProjectCreateRequest, trimmedAudioClear } from '@app/features/Projects/state';
import { useDispatch } from 'react-redux';
import FieldsForm from '@app/features/Projects/components/FieldsForm';
import { getStatusName } from '@app/utils';
import { CustomFetchBaseQueryError } from '@app/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const NewProject = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createProject, { isLoading: isProjectCreating, isSuccess }] = useCreateProjectMutation();
  const [projectId, setProjectId] = useState<number>();
  const dispatch = useDispatch();

  const onHandleSubmit = (values: FormikValues) => {
    createProject({
      name: values.name,
      primary_genre_id: values.genre.id,
      artist_id: values.artist.id,
      share_percent: values.sharePercent,
      duration_days: values.durationDays,
      goals: JSON.stringify([{ description: values.projectGoal, amount: +values.goalAmount }]),
      ...(values.description && { description: values.description }),
      ...(values.status && { status: getStatusName(values.status as string) }),
      ...(values.image && { image: values.image }),
    } as TProjectCreateRequest)
      .unwrap()
      .then(data => setProjectId(data.id))
      .catch((err: CustomFetchBaseQueryError) => showError(JSON.stringify(err)));
  };
  useEffect(() => {
    if (isSuccess && projectId) {
      navigate(`add-music?id=${projectId}`);
    }
  }, [isSuccess, projectId]);

  useEffect(() => {
    return () => {
      dispatch(trimmedAudioClear(undefined));
    };
  }, []);

  return (
    <Box>
      <Typography variant={'h4'}>{t('projects.newProject')}</Typography>
      <>
        <Formik
          initialValues={{
            name: '',
            description: '',
            artist: {
              name: '',
              id: '',
            },
            genre: {
              name: '',
              id: '',
            },
            image: '',
            sharePercent: '',
            durationDays: '',
            goalAmount: '',
            projectGoal: '',
            status: '',
          }}
          validationSchema={createNewProjectSchema}
          onSubmit={onHandleSubmit}>
          <Form>
            <Box>
              <Typography>1/2 Add Primary data</Typography>
            </Box>
            <Box mt={5} mb={2}>
              <Typography variant={'h6'}>Major info</Typography>
            </Box>
            <FieldsForm />
            <Grid container xs={5} justifyContent="flex-end">
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button style={{ width: 200, marginLeft: 10 }} type="submit" variant="contained">
                {!isProjectCreating ? (
                  'Save and continue'
                ) : (
                  <CircularProgress size={20} color={'inherit'} />
                )}
              </Button>
            </Grid>
          </Form>
        </Formik>
      </>
    </Box>
  );
};

export default NewProject;
