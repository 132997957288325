import { Avatar, Box, Button, Menu, MenuItem } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { logout } from '@app/state';
import { useRef, useState } from 'react';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  boxShadow: '0 4px 10px 0 rgba(51, 51, 51, 0.05)',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    flexDirection: 'row',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuButton = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <AppBar position="sticky">
      <Box flex={1} display="flex" justifyContent="flex-end">
        <Button ref={menuButton} onClick={() => toggleMenu()}>
          <Avatar />
        </Button>
        <Menu
          anchorEl={menuButton?.current}
          open={menuOpen}
          onClose={toggleMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
        </Menu>
      </Box>
    </AppBar>
  );
};

export default Header;
